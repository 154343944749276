<template>
  <div class="category_manage p-1">
    <div class="col-md-12">
      <el-card class="card mb-2">
        <el-button type="primary" @click="addCategory">添加类别</el-button>
        <el-button type="primary" @click="exportExcel">导出Excel</el-button>
        <br/>
        <span style="color: #666;">一级分类请通过本页面操作，二级子类别请导出Excel修改更新</span>
        <el-upload
            class="upload-demo"
            drag
            action="#"
            :http-request="handleFileChange"
            :limit="1"
            accept=".xlsx,.xls"
        >
          <i class="el-icon-upload "></i>
          <div class="el-upload__text ">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传单个xlsx文件，且不超过500kb</div>
        </el-upload>
        <el-table :data="treeData" style="width: 100%; margin-top: 20px">
          <el-table-column prop="category_id" label="类别ID" width="80"></el-table-column>
          <el-table-column prop="category_name" label="类别名称" width="300"></el-table-column>
          <el-table-column prop="recommended_score" label="推荐得分" width="300"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-button size="small" type="primary" @click="editCategory(scope.row)">编辑</el-button>
                <el-button size="small" type="success" @click="open(scope.row)">删除</el-button>
                <el-button size="small" type="danger" @click="viewSubCategories(scope.row)">查看子类别</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <!-- 删除确认对话框 -->


    <el-dialog :visible.sync="dialogVisible" title="编辑类别">
      <el-form :model="currentCategory" label-width="120px">
        <el-form-item label="类别ID">
          <el-input v-model="currentCategory.category_id" :disabled="isEditMode"></el-input>
        </el-form-item>
        <el-form-item label="类别名称">
          <el-input v-model="currentCategory.category_name"></el-input>
        </el-form-item>
        <el-form-item label="推荐分数">
          <el-input v-model="currentCategory.recommended_score" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveCategory">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="subCategoriesDialogVisible" title="子类别列表">
      <el-table :data="currentCategory.sub_categories" style="width: 100%">
        <el-table-column prop="sub_category_name" label="子类别名称"></el-table-column>
        <el-table-column prop="recommended_score" label="推荐分数"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="subCategoriesDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api";
import * as XLSX from 'xlsx';
import * as xlsx from "xlsx";

export default {
  data() {
    return {
      treeData: [],
      dialogVisible: false,
      subCategoriesDialogVisible: false,
      currentCategory: {},
      isEditMode: false,
      defaultProps: {
        children: 'sub_categories',
        label: 'category_name'
      },
      fileList: [],
      selectedFile: null,
      uploadTableData: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async submitCategoryData() {
      try {
        console.log("submitCategoryData", JSON.stringify(this.uploadTableData))
        const response = await axiosInstance.post(api.uploadSubCategory, {
          "sub_score_category_detail": JSON.parse(JSON.stringify(this.uploadTableData))
        });
        if (response.data.status == 'success') {
          this.$message.success('养成分类目更新成功');
          setTimeout(() => {
            window.location.reload();
          }, 300); // Redirect to another page after 1 second
        } else {
          this.$message.error('养成分类目更新失败，原因：' + response.data.message);
        }
      } catch (error) {
        this.$message.error('数据上传失败');
      }
    },
    handleFileChange(event) {
      this.$confirm('此操作将覆盖已有类别, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.selectedFile = event.file;
        this.fetchXLSXData();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消覆盖'
        });
      });
    },
    fetchXLSXData() {
      if (!this.selectedFile) {
        this.$message.error('请先选择文件');
        return;
      }
      this.uploadTableData = [];
      const fileReader = new FileReader()
      fileReader.onload = (file) => {
        try {
          const data = file.target.result
          const workbook = xlsx.read(data, {
            type: 'binary'
          })
          if (workbook.SheetNames.length <= 1) {
            this.$message.warning("请使用导出的模型修改并上传！");
            return;
          }
          const wsname = workbook.SheetNames[1] // 取第二张表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]) // 生成json表格内容
          let index = 0;
          for (let i of ws) {
            index++;
            const {子类别ID, 子类别名称, 上级分类ID, 上级分类名称, 推荐分数} = i
            let sub_category = {
              sub_category_id: 子类别ID,
              sub_category_name: 子类别名称,
              category_id: 上级分类ID,
              category_name: 上级分类名称,
              recommended_score: 推荐分数
            }
            const optionsName = this.treeData.map(category => category.category_name);
            const optionsId = this.treeData.map(category => category.category_id);

            let errors = []
            if (!optionsName.includes(sub_category.category_name)) {
              errors.push(`第 ${index} 行录入类型【${sub_category.category_name}】不存在，请重新录入`);
            }
            if (!optionsId.includes(sub_category.category_id)) {
              errors.push(`第 ${index} 行录入ID【${sub_category.category_id}】不匹配，请检查`);
            }
            if (sub_category.recommended_score == null || sub_category.recommended_score == 0) {
              for (const category of this.treeData) {
                if (category.category_id === sub_category.category_id) {
                  sub_category.recommended_score = category.recommended_score;
                }
              }
            }
            if (errors.length > 0) {
              this.$message.error(errors.join(',\n'));
              this.uploadTableData = [];
              this.selectedFile = null;
              this.fileList = [];
              return;
            } else {
              this.uploadTableData.push(sub_category);
            }
          }
          console.log("the data is :", this.uploadTableData);
          this.submitCategoryData();
        } catch (e) {
          console.log(e)
          this.$message.warning('请重试')
          return false
        }
      }
      fileReader.readAsBinaryString(this.selectedFile);
    },
    open(row) {
      this.$confirm('此操作将永久删除该类别, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteCategory(row);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
    ,
    fetchData() {
      axiosInstance.get(api.getCategories).then(response => {
        if (response.data.status === 'success') {
          const categories = response.data.data;
          // 处理数据以匹配 el-table 组件的格式
          this.treeData = categories.map(category => ({
            category_id: category.category_id,
            category_name: category.category_name,
            recommended_score: category.recommended_score,
            sub_categories: category.sub_categories.map(subCategory => ({
              sub_category_id: subCategory.sub_category_id,
              sub_category_name: subCategory.sub_category_name,
              recommended_score: subCategory.recommended_score
            }))
          }));
        } else {
          this.$message.error(response.data.message);
        }
      }).catch(error => {
        this.$message.error('获取分类信息失败: ' + error);
      });
    }
    ,
    handleNodeClick(data) {
      console.log('Node clicked:', data);
    }
    ,
    addCategory() {
      this.isEditMode = false;
      this.currentCategory = {category_id: '', category_name: '', recommended_score: 0};
      this.dialogVisible = true;
    }
    ,
    editCategory(category) {
      this.isEditMode = true;
      this.currentCategory = {...category};
      this.dialogVisible = true;
    }
    ,
    deleteCategory(category) {
      axiosInstance.delete(`${api.deleteCategory}/${category.category_id}`).then(response => {
        if (response.data.status === 'success') {
          this.$message.success('删除成功');
          this.fetchData();
        } else {
          this.$message.error(response.data.message);
        }
      }).catch(error => {
        this.$message.error('删除失败: ' + error);
      });
    }
    ,
    saveCategory() {
      if (this.isEditMode) {
        axiosInstance.put(`${api.updateCategory}/${this.currentCategory.category_id}`, this.currentCategory).then(response => {
          if (response.data.status === 'success') {
            this.$message.success('更新成功');
            this.fetchData();
            this.dialogVisible = false;
          } else {
            this.$message.error(response.data.message);
          }
        }).catch(error => {
          this.$message.error('更新失败: ' + error);
        });
      } else {
        axiosInstance.post(api.createCategory, this.currentCategory).then(response => {
          if (response.data.status === 'success') {
            this.$message.success('创建成功');
            this.fetchData();
            this.dialogVisible = false;
          } else {
            this.$message.error(response.data.message);
          }
        }).catch(error => {
          this.$message.error('创建失败: ' + error);
        });
      }
    }
    ,
    viewSubCategories(category) {
      this.currentCategory = category;
      this.subCategoriesDialogVisible = true;
    }
    ,
    exportExcel() {
      const mainCategories = this.treeData.map(category => ({
        '类别ID': category.category_id,
        '类别名称': category.category_name,
        '推荐分数': category.recommended_score,
      }));

      const subCategories = this.treeData.flatMap(category =>
          category.sub_categories.map(sub => ({
            '子类别ID': sub.sub_category_id,
            '子类别名称': sub.sub_category_name,
            '上级分类ID': category.category_id,
            '上级分类名称': category.category_name,
            '推荐分数': sub.recommended_score,
          }))
      );
      // 按子类别ID排序
      subCategories.sort((a, b) => a['子类别ID'] - b['子类别ID']);


      const mainWorksheet = XLSX.utils.json_to_sheet(mainCategories);
      const subWorksheet = XLSX.utils.json_to_sheet(subCategories);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, mainWorksheet, "主分类");
      XLSX.utils.book_append_sheet(workbook, subWorksheet, "子分类");

      XLSX.writeFile(workbook, "categories.xlsx");
    }
  }
};
</script>


<style>
.f-width {
  width: 220px!important;
}

</style>