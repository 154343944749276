const base = '/api'
const api = {
    Login: base + '/flyer_admin/login',
    Img: base + '/flyer_admin/captcha_image',
    saveStudentData: base + '/flyer_admin/batch_save_student',
    searchStudents: base + '/flyer_admin/search_students',
    deleteStudent: base + '/flyer_admin/delete_student',
    getClassInfo: base + '/flyer_admin/get_class_info',
    getGradeInfo: base + '/flyer_admin/get_grade_info',
    editStudentData: base + '/flyer_admin/edit_student_info',
    getAdminList: base + '/flyer_admin/get_admin_list',
    resetAdminPassword: base + '/flyer_admin/edit_admin_password',
    getClassIdsByGrades: base + '/flyer_admin/get_class_ids_by_grades',
    createAdmin: base + '/flyer_admin/create_admin',
    editAdmin: base + '/flyer_admin/edit_admin',
    getCategories: base + '/flyer_admin/get_categories',
    createDevelopmentScore: base + '/flyer_admin/create_development_score',
    queryDevelopmentScores: base + '/flyer_admin/query_development_scores',
    createDevelopmentScoresBulk: base + '/flyer_admin/batch_create_development_scores',
    updateDevelopmentScore: base + '/flyer_admin/update_development_score',
    queryStudentScores: base + '/flyer_admin/query_student_scores',
    queryClass: base + '/flyer_admin/query_class_development_scores',
    getActionList: base + '/flyer_admin/get_action_list',
    queryOpList: base + '/flyer_admin/query_operation_logs',
    queryApplyRecords: base + '/flyer_admin/query_apply_records',
    downloadFile: base + '/flyer_admin/download_file',
    updateApplyStatus: base + '/flyer_admin/update_apply_status',
    // semester
    listSemesters: base + '/flyer_admin/list_semesters',
    createSemester: base + '/flyer_admin/semesters_create',
    getSemester: (id) => base + `/flyer_admin/semesters/${id}`,
    updateSemester: (id) => base + `/flyer_admin/semesters/${id}/update`,
    deleteSemester: (id) => base + `/flyer_admin/semesters/${id}/delete`,

    createCategory: base + `/flyer_admin/score_category/create`,  // 创建新分类
    updateCategory: base + `/flyer_admin/score_category/update`,  // 更新分类
    deleteCategory: base + `/flyer_admin/score_category/delete`,  // 删除分类
    uploadSubCategory: base + '/flyer_admin/score_category/upload_sub_categories'

}
export default api
