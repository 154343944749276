<template>
  <el-card class="card">
    <div v-if="hasPermission" class="container  p-1">
      <div class="row mb-3">
        <div class="col-md-2 mb-2 mb-md-0">
          <el-select v-model="searchType" placeholder="选择查询类型" @change="handleSearchTypeChange"
                     style="width: 100%;">
            <el-option label="姓名" :value="1"></el-option>
            <el-option label="学号" :value="2"></el-option>
            <el-option label="班级" :value="3"></el-option>
            <el-option label="年级" :value="4"></el-option>
            <el-option label="所有" :value="5"></el-option>
          </el-select>
        </div>
        <div class="col-md-3 mb-2 mb-md-0" v-if="searchType !== 5">
          <el-input v-if="searchType === 1 || searchType === 2" v-model="searchValue" placeholder="输入查询值"
                    style="width: 100%;"></el-input>
          <el-select v-if="searchType === 3" v-model="searchValue" placeholder="选择班级" style="width: 100%;">
            <el-option v-for="item in classes" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-select v-if="searchType === 4" v-model="searchValue" placeholder="选择年级" style="width: 100%;">
            <el-option v-for="item in grades" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="col-md-2 mb-2 mb-md-0">
          <el-select v-model="searchProvinces" multiple placeholder="选择省份" style="width: 100%;">
            <el-option v-for="province in provinces" :key="province" :label="province" :value="province"></el-option>
          </el-select>
        </div>
        <div class="col-md-2 mb-2 mb-md-0">
          <el-select v-model="searchAirlineName" multiple placeholder="选择航空公司" style="width: 100%;">
            <el-option v-for="airline in airlines" :key="airline" :label="airline" :value="airline"></el-option>
          </el-select>
        </div>
        <div class="col-md-2">
          <el-button type="primary" @click="searchStudents" style="width: 100%;">查询</el-button>
        </div>
      </div>

      <el-table :data="students" style="width: 100%;" v-loading="loading">
        <el-table-column prop="student_number" label="学号" min-width="120"></el-table-column>
        <el-table-column prop="name" label="姓名" min-width="60"></el-table-column>
        <el-table-column prop="class_id" label="班级" min-width="60"></el-table-column>
        <el-table-column prop="grade_id" label="年级" min-width="60"></el-table-column>
        <el-table-column prop="phone" label="联系方式" min-width="120"></el-table-column>
        <el-table-column prop="province" label="省份" min-width="60"></el-table-column>
        <el-table-column prop="airline_name" label="航司" min-width="60"></el-table-column>
        <el-table-column prop="password_modified" label="初始密码修改" min-width="150">
          <template slot-scope="scope">
            <el-tag :type="scope.row.password_modified == 1 ? 'success' : 'danger'">
              {{ scope.row.password_modified == 1 ? '已修改' : '未修改' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="last_login_time" label="上次登录时间" min-width="150"></el-table-column>
        <el-table-column label="操作" min-width="220">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="small" circle @click="openEditDialog(scope.row)">
            </el-button>
            <el-button type="danger" icon="el-icon-delete" size="small" circle @click="deleteStudent(scope.row)">
            </el-button>
            <el-button type="warning" round size="small" @click="openResetPasswordDialog(scope.row)">重置密码
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-wrapper">
        <el-pagination
            background
            layout="total,sizes,prev, pager, next,jumper"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            :total="total"
            :current-page.sync="currentPage"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
            style="margin-top: 20px; text-align: center;"
        >
        </el-pagination>
      </div>

      <el-dialog title="编辑学生信息" :visible.sync="editDialogVisible" width="90%">
        <el-form :model="editForm">
          <el-form-item label="姓名">
            <el-input v-model="editForm.name" class="f-width-a" disabled></el-input>
          </el-form-item>
          <el-form-item label="班级">
            <el-select v-model="editForm.class_id" placeholder="选择班级" class="f-width-a">
              <el-option v-for="item in classes" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级">
            <el-select v-model="editForm.grade_id" placeholder="选择年级" class="f-width-a">
              <el-option v-for="item in grades" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="editForm.phone" class="f-width-a"></el-input>
          </el-form-item>
          <el-form-item label="省份">
            <el-select v-model="editForm.province" placeholder="请选择省份" class="f-width-a">
              <el-option
                  v-for="province in provinces"
                  :key="province"
                  :label="province"
                  :value="province">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="航司">
            <el-select v-model="editForm.airline_name" placeholder="请选择航司" class="f-width-a">
              <el-option
                  v-for="airline in airlines"
                  :key="airline"
                  :label="airline"
                  :value="airline">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitEdit">保存</el-button>
        </span>
      </el-dialog>

      <el-dialog title="重置密码" :visible.sync="resetPasswordDialogVisible" width="90%">
        <el-form :model="resetPasswordForm">
          <el-form-item label="学号">
            <el-input v-model="resetPasswordForm.student_number" class="f-width-a" disabled></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input type="password" v-model="resetPasswordForm.newPassword" class="f-width-a"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input type="password" v-model="resetPasswordForm.confirmPassword" class="f-width-a"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetPasswordDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitResetPassword">重置</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-else class="alert alert-danger">
      <p>你没有权限访问此页面。</p>
    </div>
  </el-card>
</template>

<script>
import api from "../../api";
import axiosInstance from "../../api/axiosInstance";
import { provinces, airlines } from '../../util/constants.js';
export default {
  data() {
    return {
      provinces,
      airlines,
      hasPermission: false,
      searchType: 5,  // 默认查询所有
      searchValue: '',
      searchProvinces: [],
      searchAirlineName: [],
      students: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      classes: [],
      grades: [],
      editDialogVisible: false,
      resetPasswordDialogVisible: false,
      editForm: {
        student_number: '',
        name: '',
        class_id: '',
        grade_id: '',
        phone: '',
        province: '',
        airline_name: ''
      },
      resetPasswordForm: {
        student_number: '',
        newPassword: '',
        confirmPassword: ''
      }
    };
  },
  methods: {
    handleSearchTypeChange() {
      this.searchValue = '';
    },
    fetchClasses() {
      axiosInstance.get(api.getClassInfo).then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          this.classes = resData.data;
        } else {
          this.$message.error(resData.message);
        }
      }).catch(() => {
        this.$message.error('获取班级信息失败，请稍后再试');
      });
    },
    fetchGrades() {
      axiosInstance.get(api.getGradeInfo).then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          this.grades = resData.data;
        } else {
          this.$message.error(resData.message);
        }
      }).catch(() => {
        this.$message.error('获取年级信息失败，请稍后再试');
      });
    },
    searchStudents() {
      if (this.searchValue === '' && this.searchType !== 5) {
        this.$message.warning("请输入查询条件")
        return;
      }
      this.loading = true;
      const params = {
        searchType: this.searchType,
        provinces: this.searchProvinces,
        airline_names: this.searchAirlineName,
        page_num: this.currentPage,
        page_total: this.pageSize,
      };

      if (this.searchType === 1) {
        params.name = this.searchValue;
      } else if (this.searchType === 2) {
        params.student_id = this.searchValue;
      } else if (this.searchType === 3) {
        params.class_id = this.searchValue;
      } else if (this.searchType === 4) {
        params.grade_id = this.searchValue;
      }

      axiosInstance.post(api.searchStudents, params, {
        headers: { Authorization: localStorage.token }
      }).then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          this.students = resData.data.students;
          this.total = resData.data.total;
        } else {
          this.$message.error(resData.message);
        }
        this.loading = false;
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.searchStudents();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.searchStudents();
    },
    openEditDialog(student) {
      this.editForm = { ...student };
      this.editDialogVisible = true;
    },
    submitEdit() {
      // 校验手机号
      if (!/^\d{11}$/.test(String(this.editForm.phone))) {
        this.$message.error('请输入正确的11位手机号');
        return;
      }
      axiosInstance.post(api.editStudentData, this.editForm, {
        headers: { Authorization: localStorage.token }
      }).then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          this.$message.success('学生信息更新成功');
          this.editDialogVisible = false;
          this.searchStudents();
        } else {
          this.$message.error(resData.message);
        }
      }).catch(() => {
        this.$message.error('学生信息更新失败，请稍后再试');
      });
    },
    deleteStudent(student) {
      this.$confirm('确定删除学生【' + student.name + '】吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.post(api.deleteStudent, {
          student_number: student.student_number,
        })
          .then(() => {
            this.searchStudents();
            this.$message.success('学生删除成功');
          })
          .catch(error => {
            this.$message.error('删除学生失败: ' + error);
          });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    openResetPasswordDialog(student) {
      this.resetPasswordForm.student_number = student.student_number;
      this.resetPasswordForm.newPassword = '';
      this.resetPasswordForm.confirmPassword = '';
      this.resetPasswordDialogVisible = true;
    },
    submitResetPassword() {
      if (this.resetPasswordForm.newPassword !== this.resetPasswordForm.confirmPassword) {
        this.$message.error('两次输入的密码不一致');
        return;
      }
      if (this.resetPasswordForm.newPassword.length < 6) {
        this.$message.error('密码长度不能少于6位');
        return;
      }
      axiosInstance.post(api.editStudentData, {
        student_number: this.resetPasswordForm.student_number,
        password: this.resetPasswordForm.newPassword
      }, {
        headers: { Authorization: localStorage.token }
      }).then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          this.$message.success('密码重置成功');
          this.resetPasswordDialogVisible = false;
        } else {
          this.$message.error(resData.message);
        }
      }).catch(() => {
        this.$message.error('密码重置失败，请稍后再试');
      })
    },
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token == null || token.length === 0) {
      this.$message.error('请先登录');
      this.$router.push({ path: "/login" });
      return;
    }
    const roleType = localStorage.getItem('role_type');
    if (roleType === '1') {
      this.hasPermission = true;
      this.fetchClasses();
      this.fetchGrades();
      this.searchStudents();  // 默认加载所有学生
    } else {
      this.$message.error('你没有权限访问此页面');
      this.$router.push({ path: "/login" });
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 12px;
}

.el-input,
.el-select {
  width: 100%;
}

.f-width {
  width: 220px;
}

.f-width-a {
  width: 100% !important;
}

.h-width {
  width: 105px !important;
}

.pagination-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  /* 保持内容不换行 */
  -ms-overflow-style: none;
  /* 针对 IE 和 Edge 隐藏滚动条 */
  scrollbar-width: none;
  /* 针对 Firefox 隐藏滚动条 */
}

.pagination-wrapper::-webkit-scrollbar {
  display: none;
  /* 针对 Chrome、Safari 隐藏滚动条 */
}

.el-pagination {
  display: inline-block;
  /* 使分页组件在一行内 */
}
</style>