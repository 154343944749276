<template>
  <div class="container-fluid p-1">
    <div class="row mb-2">
      <!-- 左侧部分 -->
      <div class="col-md-4">
        <!-- 加减分录入 -->
        <el-card class="card mb-2">
          <h3 class="card-title">养成分录入</h3>
          <el-form :inline="true" :model="form">
            <el-form-item label="输入学号">
              <el-input v-model="form.student_number" class="f-width" placeholder="输入学号"
                        @input="onStudentNumberInput"></el-input>
            </el-form-item>

            <el-form-item label="学生姓名" v-if="shouldShowAlert_name">
                {{ students_name }}
            </el-form-item>
          
            <el-form-item label="记录时间">
              <el-date-picker v-model="form.record_date" type="date" class="f-width" placeholder="选择日期"
                              format="yyyy 年 MM 月 dd 日"
                              value-format="yyyy-MM-dd"
                              :picker-options="{
                                disabledDate(time) {
                                  return time.getTime() > Date.now();
                                }
                              }"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="选择类型">
              <template v-if="isWideScreen">
                <el-cascader
                    ref="cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleTypeChange"
                    clearable></el-cascader>
              </template>
              <template v-else>
                <el-cascader-panel
                    ref="cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleTypeChange"
                    clearable></el-cascader-panel>
                <el-button @click="handleReset" class="f-width">清除</el-button>
             
               
  
              </template>
            </el-form-item>
            <el-form-item label="分数备注">
              <el-input type="textarea" v-model="form.remarks" class="f-width" placeholder="输入备注"></el-input>
            </el-form-item>
            <el-form-item label="设置分值">
              <el-input v-model="form.score" type="number" class="f-width" placeholder="设置分值"></el-input>
            </el-form-item>

            <el-form-item label="信息核对" v-if="shouldShowAlert">
                <div class="alert alert-light f-width alert-panel" ole="alert" >
                  <a v-if="students_name"><b>{{ students_name }}</b></a> <a v-if="form.score" ><a :style="{ color: scoreColor }">{{ scoreStatus }}{{ absoluteScore }}分 </a></a><br/>
                  <a v-if="selectedTypeName.length"><b>因 </b>{{ selectedTypeName }}</a>

                </div>
            </el-form-item><br/>


            <el-form-item>
              <el-button type="primary" @click="handleSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <!-- 批量导入 -->
        <el-card class="card mb-2">
          <h3 class="card-title">批量导入</h3>
          <el-upload
              class="upload-demo"
              drag
              action="#"
              :http-request="handleFileChange"
              :limit="1"
              accept=".xlsx,.xls"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传单个xlsx文件，且不超过500kb</div>
          </el-upload>
          <div v-if="selectedFileName" class="file-name">
            当前加载的文件：{{ selectedFileName }}
          </div>
          <el-button type="primary" @click="uploadFile" :disabled="!selectedFile">上传</el-button>
          <el-button type="primary" @click="exportToExcel(true)">导出模版</el-button>
        </el-card>
      </div>

      <!-- 右侧部分 -->
      <div class="col-md-8">
        <el-card class="mb-2">
          <h3 class="card-title">养成分查询</h3>
          <el-form ref="queryForm" :inline="true" :model="queryForm">
            <el-form-item label="查询类型" prop="queryType">
              <el-select v-model="queryForm.queryType" class="f-width" placeholder="选择查询类型">
                <el-option label="学号" value="student_number"></el-option>
                <el-option label="姓名" value="name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="查询内容" prop="queryValue">
              <el-input v-model="queryForm.queryValue" class="f-width" placeholder="输入查询值"></el-input>
            </el-form-item>
            <el-form-item label="起始日期" prop="startDate">
              <el-date-picker v-model="queryForm.startDate" type="date" value-format="yyyy-MM-dd"
                              class="f-width" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="终止日期" prop="endDate">
              <el-date-picker v-model="queryForm.endDate" type="date" value-format="yyyy-MM-dd"
                              class="f-width" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="学员省份" prop="provinces">
              <el-select v-model="queryForm.provinces" multiple placeholder="请选择省份" class="f-width">
                <el-option
                    v-for="province in provinces"
                    :key="province"
                    :label="province"
                    :value="province">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学员航司" prop="airline_names">
              <el-select v-model="queryForm.airline_names" multiple placeholder="请选择航司" class="f-width">
                <el-option
                    v-for="airline in airlines"
                    :key="airline"
                    :label="airline"
                    :value="airline">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择类型" prop="cascader">
              <template v-if="isWideScreen">
                <el-cascader
                    v-model="queryForm.cascader"
                    ref="search_cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleSearchTypeChange"
                    clearable></el-cascader>
              </template>
              <template v-else>
                <el-cascader-panel
                    v-model="queryForm.cascader"
                    ref="search_cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleSearchTypeChange"
                    clearable></el-cascader-panel>
                <el-button @click="handleResetSearch" class="f-width">清除</el-button>
              </template>
            </el-form-item >
            <!--文本框，审阅设置，输入框-->
            <el-form-item label="审阅设置" prop="readSet">
              <el-input v-model="queryForm.readSet" class="f-width"
                        placeholder="输入审阅人(导出PDF报告需要)"></el-input>
            </el-form-item>

            <el-form-item prop="buttonitem">
              <el-button-group>
                <el-button type="primary" @click="handleQuery(true)" icon="el-icon-search">搜索</el-button>
                <el-button type="primary" icon="el-icon-document" @click="exportPDF" :loading=exportLoading>PDF报告
                </el-button>
                <el-button type="primary" icon="el-icon-document" @click="exportPDF_individual"
                           :loading=exportSignleLoading>
                  PDF个人
                </el-button>
                <el-button type="primary" icon="el-icon-document-delete" @click="exportToExcel(false)"
                           :loading=excelLoading>Excel
                </el-button>
                <el-button type="primary" icon="el-icon-refresh" @click="onReset">重置</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table :data="tableData" style="width: 100%" @sort-change="handleSortChange">
            <el-table-column prop="student_number" label="学号" sortable="custom"></el-table-column>
            <el-table-column prop="name" label="姓名" sortable="custom"></el-table-column>
            <el-table-column prop="category" label="类型" sortable="custom"></el-table-column>
            <el-table-column prop="sub_category" label="子类型" sortable="custom"></el-table-column>
            <el-table-column prop="score" label="分值" sortable="custom"></el-table-column>
            <el-table-column prop="remarks" label="备注"></el-table-column>
            <el-table-column prop="record_date" label="时间" sortable="custom"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status === 0">生效</span>
                <span v-else-if="scope.row.status === 1">作废</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="openEditDialog(scope.row)" icon="el-icon-edit" circle></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper">
            <el-pagination
                background
                layout="total,sizes,prev, pager, next,jumper"
                :total="total"
                :page-sizes="[10, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
            />
          </div>
        </el-card>
      </div>
      <!-- 编辑对话框 -->
      <el-dialog title="编辑记录" :visible.sync="editDialogVisible" width="80%">
        <el-form :model="editForm" label-width="80px">
          <el-form-item label="学号">
            <el-input v-model="editForm.student_number" class="f-width-a" disabled></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="editForm.name" class="f-width-a" disabled></el-input>
          </el-form-item>

          <el-form-item label="类型">
            <template v-if="isWideScreen">
              <el-cascader
                  ref="edit_cascader"
                  size="medium"
                  v-model="editForm.value"
                  :options="categories_cascader"
                  :props="{ checkStrictly: true }"
                  class="f-width-a" placeholder="选择类型"
                  @change="handleEditTypeChange"
                  clearable></el-cascader>
            </template>
            <template v-else>
              <el-cascader-panel
                  ref="edit_cascader"
                  size="medium"
                  v-model="editForm.value"
                  :options="categories_cascader"
                  :props="{ checkStrictly: true }"
                  class="f-width-a" placeholder="选择类型"
                  @change="handleEditTypeChange"
                  clearable></el-cascader-panel>
              <el-button @click="handleResetEdit" class="f-width-a">清除</el-button>
            </template>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="editForm.remarks" class="f-width-a"></el-input>
          </el-form-item>
          <el-form-item label="分值">
            <el-input v-model="editForm.score" type="number" class="f-width-a"></el-input>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker v-model="editForm.record_date" type="date" value-format="yyyy-MM-dd"
                            class="f-width-a"></el-date-picker>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="editForm.status" class="f-width-a" placeholder="选择状态">
              <el-option label="生效中" :value="0"></el-option>
              <el-option label="已作废" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitEdit">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '../../api';
import axiosInstance from "../../api/axiosInstance";
import 'jspdf-autotable';
import jsPDF from "jspdf";
import '../../assets/SimHei-normal.js'
import * as xlsx from "xlsx";
import { saveAs } from 'file-saver';
import XlsxPopulate from 'xlsx-populate';
import { provinces, airlines } from '../../util/constants.js';

import JSZip from 'jszip';  // 导入JSZip库


export default {
  data() {
    return {
      provinces,
      airlines,
      isWideScreen: window.innerWidth >= 768, // 可以根据需要调整宽度阈值
      loading: false,
      searchValue: '',
      students_name: '',
      sortOrder: null, // 排序顺序
      sortField: null, // 排序字段
      editDialogVisible: false,
      editForm: {
        id: null,
        category: '',
        category_id: 0,
        sub_category: '',
        sub_category_id: 0,
        remarks: '',
        score: 0,
        record_date: '',
        value: [],
        status: 0
      },
      selectedTypeName: '',
      selectedFile: null,
      selectedFileName: '',
      uploadTableData: [],
      exportLoading: false,
      exportSignleLoading: false,
      excelLoading: false,
      form: {
        student_number: '',
        remarks: '',
        score: 0,
        record_date: '',
        type_name: '',
        sub_type_name: '',
        type_id: null,
        sub_type_id: null,
        select_path: [],
      },
      search_form: {
        student_number: '',
        name: '',
        start_date: '',
        end_date: '',
        type: '',
        sub_type: '',
        provinces: [],
        airline_names: [],
        type_id: 0,
        sub_type_id: 0,
        readSet: '',
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      queryForm: {
        queryType: '',
        queryValue: '',
        startDate: '',
        endDate: '',
        type: '',
        sub_type: '',
        provinces: [],
        airline_names: [],
        type_id: 0,
        sub_type_id: 0,
        readSet: '',
      },
      tableData: [
        // Mock 数据
      ],
      categories: [],
      categories_cascader: [],

    };
  },
  mounted() {

    const token = localStorage.getItem('token');
    if (token == null || token.length === 0) {
      this.$message.error('请先登录');
      this.$router.push({ path: "/login" });
      return;
    }
    this.fetchCategories();
    this.handleQuery();
  },
  computed: {
    absoluteScore() {
      return Math.abs(parseFloat(this.form.score));
    },
    // 计算颜色

    scoreColor() {
      const score = parseFloat(this.form.score);

      if (!isNaN(score)) {
        return score > 0 ? 'green' : score < 0 ? 'red' : 'gray';
      }
      return 'gray'; // 默认颜色
    },
    // 计算状态
    scoreStatus() {
      const score = parseFloat(this.form.score);
      if (!isNaN(score)) {
        return score > 0 ? '加' : score < 0 ? '扣' : '//';
      }
      return '所设置分数为0'; // 默认状态
    },
    shouldShowAlert() {
      return this.students_name && this.form.score && this.selectedTypeName.length;
    },
    shouldShowAlert_name() {
      return this.students_name;
    }
  },
  methods: {
    onStudentNumberInput() {
      this.searchStudents();
    },
    onReset() {
      this.$refs.queryForm.resetFields(); // 重置表单字段
    },
    searchStudents() {
      this.loading = true;
      const params = {
        searchType: 2,
        page_num: 1, // Or any other default value
        page_total: 10, // Or any other default value
        student_id: this.form.student_number,
      };
      this.students_name = null;
      axiosInstance.post(api.searchStudents, params, {
        headers: { Authorization: localStorage.token }
      }).then(response => {
        const resData = response.data;
        if (resData.status === 'success') {
          //console.log(resData.data.students[0].student_number);

          resData.data.total != 1 ? this.students_name = null : this.students_name = resData.data.students[0].name;
          resData.data.students[0].student_number != this.form.student_number ? this.students_name = null : this.students_name = resData.data.students[0].name;
        } else {
          this.students_name = null;
        }
        this.loading = false;
      }).catch(error => {
        this.students_name = null;
        this.loading = false;
      });
    },
    handleReset() {
      this.$refs.cascader.checkedValue = "";
    },
    handleResetSearch() {
      this.$refs.search_cascader.checkedValue = "";
    },
    handleResetEdit() {
      this.$refs.edit_cascader.checkedValue = "";
    },
    handleSortChange({ prop, order }) {
      this.sortField = prop;
      this.sortOrder = order === 'ascending' ? 'asc' : 'desc';
      this.handleQuery();
    },
    submitEdit() {
      axiosInstance.post(api.updateDevelopmentScore, this.editForm)
        .then(response => {
          if (response.data.status === 'success') {
            this.$message.success('记录更新成功');
            this.editDialogVisible = false;
            this.handleQuery();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(() => {
          this.$message.error('记录更新失败，请稍后再试');
        });
    },
    openEditDialog(row) {
      console.log(row);
      this.editForm = { ...row };
      this.editForm.value = [];
      const selectedCategory = this.categories.find(category =>
        category.category_name === row.category);
      if (selectedCategory) {
        this.editForm.value.push(selectedCategory.category_id);
        if (row.sub_category.length > 0) {
          const subCategory = selectedCategory.sub_categories.find(sub => sub.sub_category_name === row.sub_category);
          if (subCategory) {
            this.editForm.value.push(subCategory.sub_category_id);
          }
        }
      }
      this.editDialogVisible = true;
    },
    beforeUpload(file) {
      const isXLSX = file.name.endsWith('.xlsx');
      if (!isXLSX) {
        this.$message.error('请上传xlsx格式文件');
      }
      return isXLSX;
    },
    async fetchAllData(params) {
      let allData = [];
      let currentPage = 1;
      let totalPages = 3;
      do {
        params.page = currentPage;
        const response = await axiosInstance.get(api.queryDevelopmentScores, { params });
        const resData = response.data.data;
        allData.push(...resData.scores);
        currentPage += 1;
        totalPages = resData.total / params.page_size;
        if (resData.total % params.page_size > 0) {
          totalPages += 1;
        }
      } while (currentPage <= totalPages);
      console.log("allData", allData);
      return allData;
    },
    async getData() {
      const params = {
        name: this.queryForm.queryType === 'name' ? this.queryForm.queryValue : '',
        student_number: this.queryForm.queryType === 'student_number' ? this.queryForm.queryValue : '',
        start_date: this.queryForm.startDate,
        end_date: this.queryForm.endDate,
        category: this.queryForm.type,
        sub_category: this.queryForm.sub_type,
        provinces: this.queryForm.provinces,
        airline_names: this.queryForm.airline_names,
        page: 1,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder
      };
      return await this.fetchAllData(params);
    },

    generateRows(data) {
      return data.map(item => [
        item.student_number,
        item.name,
        item.category + (item.sub_category.length > 0 ? '-' + item.sub_category : ''),
        item.remarks,
        item.score,
        item.record_date
      ]);
    },

    generateRowsToExcel(data) {
      return data.map(item => ({
        student_number: item.student_number,
        name: item.name,
        category: item.category,
        sub_category: item.sub_category,
        score: item.score,
        remarks: item.remarks,
        record_date: item.record_date,
        status: item.status == 0 ? '生效' : '作废',
      }));
    },

    async exportToExcel(isModel) {
      if (!isModel) {
        this.excelLoading = true;
        try {
          const data = await this.getData();
          const rows = this.generateRowsToExcel(data);
          console.log(rows);
          const ws = xlsx.utils.json_to_sheet(rows);
          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Report");
          xlsx.writeFile(wb, "report.xlsx");
        } catch (error) {
          console.error("Error exporting to Excel:", error);
        } finally {
          this.excelLoading = false;
        }
      } else {
        // let modelRows = [{
        //   学号: '确保已录入',
        //   加减分类型: '输入加减分类型',
        //   加减分分值: '可为空',
        //   备注信息: '可为空',
        //   时间: 'yyyy-mm-dd'
        // }]
        // const ws = xlsx.utils.json_to_sheet(modelRows);
        // const wb = xlsx.utils.book_new();
        // xlsx.utils.book_append_sheet(wb, ws, "Model");
        // xlsx.writeFile(wb, "inputModel.xlsx");
        const optionsList = this.categories.map(category => category.category_name);
        XlsxPopulate.fromBlankAsync()
          .then(workbook => {
            const sheet = workbook.sheet(0);
            sheet.name("Model");

            // 添加表头
            sheet.cell("A1").value("学号");
            sheet.cell("B1").value("加减分类型");
            sheet.cell("C1").value("加减分分值");
            sheet.cell("D1").value("备注信息");
            sheet.cell("E1").value("时间");

            // 添加数据验证（下拉列表）到第二列（加减分类型）
            sheet.range("B2:B1000").dataValidation({
              type: "list",
              allowBlank: true,
              formula1: `"${optionsList.join(",")}"`,
              showDropDown: true
            });

            // 填充一个示例行
            sheet.cell("A2").value("确保已录入");
            sheet.cell("B2").value("输入加减分类型");
            sheet.cell("E2").value("yyyy-mm-dd");

            // 生成二进制内容并保存文件
            return workbook.outputAsync();
          })
          .then(blob => {
            saveAs(blob, "inputModel.xlsx");
          })
          .catch(err => {
            console.error(err);
          });
      }
    },

    async exportPDF_individual() {

      if (this.queryForm.readSet.length === 0) {
        this.$message.error('请输入审阅人');
        return;
      }
      this.exportSignleLoading = true;

      // 获取数据
      let data = await this.getData();
      console.log("data:", data);

      // 对数据按人分组
      let groupedData = this.groupDataByPerson(data);
      console.log("groupedData:", groupedData);

      // 获取当前日期时间
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const exportDate = `${year}年${month}月${day}日`;
      const zip = new JSZip();  // 创建一个新的ZIP实例

      const img = new Image();
      img.src = require('@/assets/logo.png'); // 使用相对路径l

      // 循环遍历每个人的数据，生成独立的PDF
      for (let person in groupedData) {
        let data = groupedData[person];
        // 过滤 data 数组，保留 status 为 0 的元素
        let filteredData = data.filter(item => item.status === 0);
        if (filteredData.length === 0) {
          continue;
        }
        let rows = this.generateRows(filteredData);
        console.log(filteredData)

        // 初始化累积扣分和加分记录
        let totalDeduction = 0;  // 累积扣分数
        let totalBonus = 0;      // 累积加分数

        // 遍历 filteredData 并进行计算
        filteredData.forEach(item => {
          const score = parseFloat(item.score); // 将字符串转换为数字
          if (!isNaN(score)) {  // 确保 score 是有效数字
            if (score < 0) {
              totalDeduction += score;  // 累加到扣分数中
            } else if (score > 0) {
              totalBonus += score;      // 累加到加分记录中
            }
          }
        });
        // 添加累积扣分数和加分记录到表格数据
        rows.push(["累积扣分数", "", "", totalDeduction.toFixed(2), ""]);
        rows.push(["累积加分数", "", "", totalBonus.toFixed(2), ""]);

        const doc = new jsPDF();
        doc.setFont('SimHei');

        // 添加图片
        doc.addImage(img, 'PNG', 40, 10, 125, 30); // 图片在中间

        // 添加标题
        doc.setFontSize(18);
        doc.text('飞行学员养成分情况通报', 105, 50, { align: 'center' });

        // 添加日期范围
        if (this.queryForm.startDate != null) {
          if (this.queryForm.startDate.length > 0) {
            doc.setFontSize(12);
            let text = this.queryForm.startDate;
            if (this.queryForm.endDate != null) {
              if (this.queryForm.endDate.length > 0) {
                text += ' 至 ' + this.queryForm.endDate;
              } else {
                text += ' 至今';
              }
            }
            doc.text(text, 105, 60, { align: 'center' });
          }
        }


        // 添加说明文字
        doc.setFontSize(12);
        doc.text('经指导、教官检查，报院学生工作办公室批准，现给予以下同学：', 15, 70);

        // 定义表格内容
        const columns = [
          "学号",
          "姓名",
          "原因",
          "备注",
          "分值",
          "时间"
        ];

        // 创建表格
        doc.autoTable({
          head: [columns],
          body: rows,
          startY: 80, // 表格起始位置
          styles: {
            font: 'SimHei', // 设置表格字体
            fontStyle: 'normal', // 设置表格字体样式
            lineColor: [0, 0, 0], // 设置表格边框颜色为黑色
            lineWidth: 0.1 // 设置表格边框宽度
          },
          bodyStyles: {
            fillColor: null, // 设置表格内容单元格背景颜色为透明
          },
          headStyles: {
            fillColor: [255, 255, 255], // 设置表头单元格背景颜色为白色
            textColor: [0, 0, 0], // 设置表头文字颜色为黑色
            lineWidth: 0.1 // 设置表头边框宽度
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255], // 确保交替行的背景颜色为白色
          }
        });

        // 获取表格结束位置
        const finalY = doc.autoTable.previous.finalY;
        const pageHeight = doc.internal.pageSize.height;
        let footerStartY = finalY + 20;

        // 检查是否需要新页面
        if (footerStartY + 60 > pageHeight) {
          doc.addPage();
          footerStartY = 20; // 新页面的起始位置
        }

        // 添加尾部内容
        doc.setFont('SimHei');
        doc.setFontSize(12);

        doc.setDrawColor(255, 0, 0);
        doc.setLineWidth(0.5);

        doc.line(15, footerStartY, 190, footerStartY);

        doc.text('北航飞行学院学生工作办公室', 15, footerStartY + 5, { align: 'left' });

        doc.line(15, footerStartY + 8, 190, footerStartY + 8);

        doc.setFontSize(10);
        doc.text('通报：学生工作办公室张贴：1 份', 15, footerStartY + 15, { align: 'left' });

        doc.text('审阅：' + this.queryForm.readSet, 15, footerStartY + 30);
        doc.text('关键词：北航飞行学院 纪律检查', 15, footerStartY + 40);

        doc.text(`${exportDate}`, 190, footerStartY + 50, { align: 'right' });

        // 以人名命名文件名并保存
        const fileName = `报告-${person}-${exportDate}.pdf`;
        //doc.save(fileName);
        const pdfData = doc.output('arraybuffer'); // 获取PDF数据为ArrayBuffer
        zip.file(fileName, pdfData); // 将PDF文件添加到zip中
      }


      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `飞行学员报告-${exportDate}.zip`);
      });


      this.exportSignleLoading = false;
    },

    // 根据人名对数据进行分组
    groupDataByPerson(data) {
      let groupedData = {};
      data.forEach(item => {
        const person = item.name; // 假设数据项中的人名字段为 "name"

        const student_number = item.student_number;
        const category = student_number + person;
        if (!groupedData[category]) {
          groupedData[category] = [];
        }
        groupedData[category].push(item);
      });
      return groupedData;
    },


    async exportPDF() {

      if (this.queryForm.readSet.length === 0) {
        this.$message.error('请输入审阅人');
        return;
      }
      this.exportLoading = true;

      let data = await this.getData();
      console.log("data", data)
      // 过滤 data 数组，保留 status 为 0 的元素
      let filteredData = data.filter(item => item.status === 0);
      let rows = this.generateRows(filteredData);
      console.log(filteredData)

      // 初始化累积扣分和加分记录
      let totalDeduction = 0;  // 累积扣分数
      let totalBonus = 0;      // 累积加分数

      // 遍历 filteredData 并进行计算
      filteredData.forEach(item => {
        const score = parseFloat(item.score); // 将字符串转换为数字
        if (!isNaN(score)) {  // 确保 score 是有效数字
          if (score < 0) {
            totalDeduction += score;  // 累加到扣分数中
          } else if (score > 0) {
            totalBonus += score;      // 累加到加分记录中
          }
        }
      });
      console.log(totalDeduction);
      console.log(totalBonus);

      // 添加累积扣分数和加分记录到表格数据
      rows.push(["累积扣分数", "", "", totalDeduction.toFixed(2), ""]);
      rows.push(["累积加分数", "", "", totalBonus.toFixed(2), ""]);


      const doc = new jsPDF();
      doc.setFont('SimHei');

      // 添加图片
      const img = new Image();
      img.src = require('@/assets/logo.png'); // 使用相对路径
      // 添加压缩后的图片到PDF，注意宽高可能需要调整
      doc.addImage(img, 'PNG', 40, 10, 125, 30); // 图片在中间

      // 添加标题
      doc.setFontSize(18);
      doc.text('飞行学员养成分情况通报', 105, 50, { align: 'center' });

      // 添加日期范围
      if (this.queryForm.startDate != null) {
        if (this.queryForm.startDate.length > 0) {
          doc.setFontSize(12);
          let text = this.queryForm.startDate;
          if (this.queryForm.endDate != null) {
            if (this.queryForm.endDate.length > 0) {
              text += ' 至 ' + this.queryForm.endDate;
            } else {
              text += ' 至今';
            }
          }
          doc.text(text, 105, 60, { align: 'center' });
        }
      }

      // 添加说明文字
      doc.setFontSize(12);
      doc.text('经指导、教官检查，报院学生工作办公室批准，现给予以下同学：', 15, 70);

      // 定义表格内容
      const columns = [
        "学号",
        "姓名",
        "原因",
        "备注",
        "分值",
        "时间"
      ];

      // 获取当前日期时间
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const exportDate = `${year}年${month}月${day}日`;
      const exportDate_short = `${month}月${day}日`;
      // 创建表格
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 80, // 表格起始位置
        styles: {
          font: 'SimHei', // 设置表格字体
          fontStyle: 'normal', // 设置表格字体样式
          lineColor: [0, 0, 0], // 设置表格边框颜色为黑色
          lineWidth: 0.1 // 设置表格边框宽度
        },
        bodyStyles: {
          fillColor: null, // 设置表格内容单元格背景颜色为透明
        },
        headStyles: {
          fillColor: [255, 255, 255], // 设置表头单元格背景颜色为白色
          textColor: [0, 0, 0], // 设置表头文字颜色为黑色
          lineWidth: 0.1 // 设置表头边框宽度
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255], // 确保交替行的背景颜色为白色
        }
      });

      // 获取表格结束位置
      const finalY = doc.autoTable.previous.finalY;
      const pageHeight = doc.internal.pageSize.height;
      let footerStartY = finalY + 20;

      // 检查是否需要新页面
      if (footerStartY + 60 > pageHeight) {
        doc.addPage();
        footerStartY = 20; // 新页面的起始位置
      }

      // 添加尾部内容
      doc.setFont('SimHei');
      doc.setFontSize(12);

      doc.setDrawColor(255, 0, 0);
      doc.setLineWidth(0.5);

      doc.line(15, footerStartY, 190, footerStartY);

      doc.text('北航飞行学院学生工作办公室', 15, footerStartY + 5, { align: 'left' });

      doc.line(15, footerStartY + 8, 190, footerStartY + 8);

      doc.setFontSize(10);
      doc.text('通报：学生工作办公室张贴：1 份', 15, footerStartY + 15, { align: 'left' });

      doc.text('审阅：' + this.queryForm.readSet, 15, footerStartY + 30);
      doc.text('关键词：北航飞行学院 纪律检查', 15, footerStartY + 40);
      doc.text('公示时间为三天，在公示期间有异议者，联系带班辅导员。', 15, footerStartY + 50);


      doc.text(`${exportDate}`, 190, footerStartY + 60, { align: 'right' });

      doc.save('' + exportDate_short + '通报批评.pdf');
      this.exportLoading = false;
    },

    formatCategories(categories) {
      let formattedCategories = [];
      for (let category of categories) {
        let formattedCategory = {
          value: category.category_id,
          label: category.category_name,
          children: []
        };
        for (let sub of category.sub_categories) {
          formattedCategory.children.push({
            value: sub.sub_category_id,
            label: sub.sub_category_name,
          });
        }
        formattedCategories.push(formattedCategory);
      }
      return formattedCategories;
    },
    fetchCategories() {
      axiosInstance.get(api.getCategories).then(response => {
        if (response.data.status === 'success') {
          this.categories = response.data.data;
          this.categories_cascader = this.formatCategories(this.categories);
        } else {
          this.$message.error(response.data.message);
        }
      }).catch(error => {
        this.$message.error('获取分类信息失败' + error);
      });
    },
    handleEditTypeChange(val) {
      console.log(val)
      const checkedNodes = this.$refs['edit_cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        console.log("data changed");
        this.editForm.category = '';
        this.editForm.category_id = 0;
        this.editForm.sub_category = '';
        this.editForm.sub_category_id = 0;
        return;
      }
      if (checkedNodes[0].path.length > 0) {
        this.editForm.category_id = checkedNodes[0].path[0];
        if (checkedNodes[0].path.length > 1) {
          this.editForm.sub_category_id = checkedNodes[0].path[1];
        }
      }
      const selectedCategory = this.categories.find(category =>
        category.category_id === this.editForm.category_id);
      if (selectedCategory) {
        const subCategory = selectedCategory.sub_categories.find(sub => sub.sub_category_id === this.editForm.sub_category_id);
        this.editForm.category = selectedCategory.category_name;
        if (subCategory) {
          this.editForm.sub_category = subCategory.sub_category_name;
        }
      } else {
        this.editForm.category = '';
        this.editForm.category_id = 0;
        this.editForm.sub_category = '';
        this.editForm.sub_category_id = 0;
      }
      console.log(this.editForm);
    },
    handleSearchTypeChange(val) {
      console.log(val)
      this.queryForm.type = '';
      this.queryForm.type_id = 0;
      this.queryForm.sub_type = '';
      this.queryForm.sub_type_id = 0;
      const checkedNodes = this.$refs['search_cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        console.log("data changed");
        this.queryForm.type = '';
        this.queryForm.type_id = 0;
        this.queryForm.sub_type = '';
        this.queryForm.sub_type_id = 0;
        return;
      }
      if (checkedNodes[0].path.length > 0) {
        this.queryForm.type_id = checkedNodes[0].path[0];
        if (checkedNodes[0].path.length > 1) {
          this.queryForm.sub_type_id = checkedNodes[0].path[1];
        }
      }
      const selectedCategory = this.categories.find(category =>
        category.category_id === this.queryForm.type_id);
      if (selectedCategory) {
        const subCategory = selectedCategory.sub_categories.find(sub => sub.sub_category_id === this.queryForm.sub_type_id);
        this.queryForm.type = selectedCategory.category_name;
        if (subCategory) {
          this.queryForm.sub_type = subCategory.sub_category_name;
        }
      } else {
        this.queryForm.type = '';
        this.queryForm.sub_type = '';
      }
      console.log(this.queryForm);
    },
    handleTypeChange(val) {
      this.form.type_name = '';
      this.form.type_id = 0;
      this.form.sub_type_name = '';
      this.form.sub_type_id = 0;
      const checkedNodes = this.$refs['cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        this.form.type_name = '';
        this.form.type_id = 0;
        this.form.sub_type_name = '';
        this.form.sub_type_id = 0;
        return;
      }
      if (checkedNodes[0].path.length > 0) {
        this.form.type_id = checkedNodes[0].path[0];
        if (checkedNodes[0].path.length > 1) {
          this.form.sub_type_id = checkedNodes[0].path[1];
        }
      }
      if (this.form.type_id == null && this.form.sub_type_id == null) {
        return;
      }
      const selectedCategory = this.categories.find(category =>
        category.category_id === this.form.type_id);
      if (selectedCategory) {
        const subCategory = selectedCategory.sub_categories.find(sub => sub.sub_category_id === this.form.sub_type_id);
        this.form.type_name = selectedCategory.category_name;
        this.form.type_id = selectedCategory.category_id;
        this.form.score = selectedCategory.recommended_score || 0;
        this.selectedTypeName = selectedCategory.category_name;
        if (subCategory) {
          this.form.sub_type_name = subCategory.sub_category_name;
          this.form.sub_type_id = subCategory.sub_category_id;
          this.form.score = subCategory.recommended_score || 0;
          this.selectedTypeName = selectedCategory.category_name + ' - ' + subCategory.sub_category_name;
        }
      } else {
        this.form.score = 0;
        this.form.type_name = '';
        this.form.sub_type_name = '';
        this.form.type_id = null;
        this.form.sub_type_id = null;
      }
      console.log(this.form);
    },
    handleSubmit() {
      if (!this.form.student_number || !this.form.record_date || !this.form.type_name) {
        this.$message.error('学号、时间和类型不能为空');
        return;
      }

      if (this.form.score === 0) {
        this.$message.error('分值不能为0');
        return;
      }
      axiosInstance.post(api.createDevelopmentScore, this.form)
        .then(response => {
          const resData = response.data;
          if (resData.status === 'success') {
            this.$message.success('记录创建成功');
            // 清空表单
            this.form = {
              student_number: '',
              remarks: '',
              score: 0,
              record_date: '',
              type_name: '',
              sub_type_name: '',
              type_id: null,
              sub_type_id: null,
              select_path: [],
              students_name: ''

            };
            this.$refs.cascader.checkedValue = "";

            this.handleQuery();
            //影藏两个alert
            this.students_name = null;

          } else {
            this.$message.error(resData.message);
          }
        })
        .catch(() => {
          this.$message.error('记录创建失败，请稍后再试');
        });
      // 提交后主动刷新
      this.$nextTick(() => {
        this.handleQuery();
      });
    },
    handleFileChange(event) {
      this.students = [];
      const file = event.file
      this.selectedFile = file;
      this.fetchXLSXData();
    },
    uploadFile() {
      console.log(this.uploadTableData);
      if (this.uploadTableData.length === 0) {
        this.$message.warning("无可上传数据");
      }
      axiosInstance.post(api.createDevelopmentScoresBulk, { scores: this.uploadTableData })
        .then(response => {
          if (response.data.status === 'success') {
            this.$message.success('批量创建成功');
            this.handleQuery();  // 刷新查询结果
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(() => {
          this.$message.error('批量创建失败，请稍后再试');
        });
    },
    fetchXLSXData() {
      if (!this.selectedFile) {
        this.$message.error('请先选择文件');
        return;
      }
      this.uploadTableData = [];
      const fileReader = new FileReader()
      fileReader.onload = (file) => {
        try {
          const data = file.target.result
          const workbook = xlsx.read(data, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0] // 取第一张表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]) // 生成json表格内容
          // console.log(ws, 'ws是表格里的数据，且是json格式')
          let index = 0;
          for (let i of ws) {
            index++;
            const { 学号, 加减分类型, 加减分分值, 备注信息, 时间 } = i
            let record_date;
            if (typeof 时间 === 'number') {
              record_date = new Date(Math.round((时间 - 25569) * 86400 * 1000)).toISOString().split('T')[0];
            } else {
              record_date = 时间;
            }
            let score = {
              student_number: 学号,
              category: 加减分类型,
              score: 加减分分值,
              remarks: 备注信息,
              record_date: record_date
            }
            const optionsList = this.categories.map(category => category.category_name);


            let errors = []
            if (!optionsList.includes(score.category)) {
              errors.push(`第 ${index} 行录入类型【${score.category}】不存在，请重新录入`);
            }
            if (!score.student_number || !score.category || !score.record_date) {
              errors.push(`第 ${index} 行信息不足`);
            }
            if (errors.length > 0) {
              this.$message.error(errors.join(',\n'));
              this.uploadTableData = [];
              return;
            } else {
              this.uploadTableData.push(score);
            }
          }
        } catch (e) {
          console.log(e)
          this.$message.warning('请重试')
          return false
        }
      }
      fileReader.readAsBinaryString(this.selectedFile);
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.handleQuery();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.handleQuery();
    },
    handleQuery(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
      }
      const params = {
        name: this.queryForm.queryType === 'name' ? this.queryForm.queryValue : '',
        student_number: this.queryForm.queryType === 'student_number' ? this.queryForm.queryValue : '',
        start_date: this.queryForm.startDate,
        end_date: this.queryForm.endDate,
        provinces: this.queryForm.provinces,
        airline_names: this.queryForm.airline_names,
        category: this.queryForm.type,
        sub_category: this.queryForm.sub_type,

        page: this.currentPage, // 当前页数，假设你有currentPage变量
        page_size: this.pageSize, // 每页大小，假设你有pageSize变量
        sort_field: this.sortField,
        sort_order: this.sortOrder
      };
      axiosInstance.get(api.queryDevelopmentScores, { params })
        .then(response => {
          if (response.data.status === 'success') {
            this.tableData = response.data.data.scores;
            console.log(this.tableData)
            this.total = response.data.data.total;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error('查询失败' + error);
        });
    }
  }
  ,
}
  ;
</script>

<style scoped>
.card-title {
  font-size: 1rem;
  /* Adjust this to your preferred size */
  margin-bottom: 0.1rem;
}

.f-width {
  width: 220px !important;
}

.f-width-a {
  width: 100% !important;
}

.f-width-b {
  width: 290px !important;
}

.h-width {
  width: 105px !important;
}

.el-cascader-panel {
  width: 220px;
  overflow-x: auto;
  /* 当内容超出宽度时显示横向滚动条 */
}

.alert-panel {
  overflow-x: auto;
  white-space: nowrap;
}

.pagination-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  /* 保持内容不换行 */
  -ms-overflow-style: none;
  /* 针对 IE 和 Edge 隐藏滚动条 */
  scrollbar-width: none;
  /* 针对 Firefox 隐藏滚动条 */
}

.pagination-wrapper::-webkit-scrollbar {
  display: none;
  /* 针对 Chrome、Safari 隐藏滚动条 */
}

.el-pagination {
  display: inline-block;
  /* 使分页组件在一行内 */
}
</style>