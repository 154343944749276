<template>
  <div class="container p-1">
    <!-- 查询选项 -->
    <div class="row mb-2">
      <div class="col-md-12">
        <el-card class="card">
          <h3 class="card-title">操作记录查询</h3>
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="开始时间">
              <el-date-picker v-model="searchForm.startDate" type="date" class="f-width" value-format="yyyy-MM-dd"
                              placeholder="选择起始日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker v-model="searchForm.endDate" type="date" class="f-width" value-format="yyyy-MM-dd"
                              placeholder="选择截止日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="操作角色">
              <el-select v-model="searchForm.operatorRole" class="f-width" placeholder="选择操作人角色">
                <el-option label="辅导员" value="辅导员"></el-option>
                <el-option label="学生" value="学生"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="操作人ID">
              <el-input v-model="searchForm.operatorId" class="f-width" placeholder="输入操作人ID"></el-input>
            </el-form-item>
            <el-form-item label="被操作角色">
              <el-select v-model="searchForm.operatedRole" class="f-width" placeholder="选择被操作人角色">
                <el-option label="辅导员" value="辅导员"></el-option>
                <el-option label="学生" value="学生"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="被操作人ID">
              <el-input v-model="searchForm.operatedId" class="f-width" placeholder="输入被操作人ID"></el-input>
            </el-form-item>
            <el-form-item label="操作行为">
              <el-select v-model="searchForm.actions" class="f-width" multiple placeholder="选择操作行为">
                <el-option v-for="action in actions" :key="action" :label="action" :value="action"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch(true, true)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <!-- 查询结果 -->
    <div class="row mt-2">
      <div class="col-md-12">
        <el-card>
          <el-table :data="tableData" style="width: 100%" @sort-change="handleSortChange">
            <el-table-column prop="operator_id" label="操作人ID" width="100" sortable="custom"></el-table-column>
            <el-table-column prop="operator_role" label="操作人角色" width="120" sortable="custom"></el-table-column>
            <el-table-column prop="operator_role_name" label="操作人姓名" width="120"></el-table-column>
            <el-table-column prop="target_id" label="被操作人ID" width="100" sortable="custom"></el-table-column>
            <el-table-column prop="target_role" label="被操作人角色" width="120" sortable="custom"></el-table-column>
            <el-table-column prop="target_role_name" label="操作人姓名" width="120"></el-table-column>
            <el-table-column prop="action" label="操作行为" width="200" sortable="custom"></el-table-column>
            <el-table-column prop="details" label="操作内容" width="200" sortable="custom"></el-table-column>
            <el-table-column prop="operation_time" label="操作时间" width="180" sortable="custom"></el-table-column>
          </el-table>
          <div class="pagination-wrapper">
            <el-pagination
                background
                layout="total,sizes,prev, pager, next,jumper"
                :total="total"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="pageSize"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
            />
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";  // 假设你有这个实例配置
import api from "../../api/index";

export default {
  data() {
    return {
      searchForm: {
        startDate: '',
        endDate: '',
        operatorId: '',
        operatorRole: '',
        operatedId: '',
        operatedRole: '',
        actions: []
      },
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      actions: [],
    };
  },
  methods: {
    handleSearch(showInfo, resetPage) {
      if (resetPage) {
        this.currentPage = 1;
      }
      // 将查询表单数据转换为请求参数
      const params = {
        start_date: this.searchForm.startDate,
        end_date: this.searchForm.endDate,
        operator_id: this.searchForm.operatorId,
        operator_role: this.searchForm.operatorRole,
        operated_id: this.searchForm.operatedId,
        operated_role: this.searchForm.operatedRole,
        actions: this.searchForm.actions,  // 将数组转换为逗号分隔的字符串
        page: this.currentPage,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder
      };

      // 发送 GET 请求到后端 API
      axiosInstance.get(api.queryOpList, { params })
        .then(response => {
          if (response.data.status === 'success') {
            // 更新表格数据和总数
            this.tableData = response.data.data.logs;
            this.total = response.data.data.total;
            if (showInfo) {
              this.$message.success("查询成功")
            }
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error('获取数据失败：' + error);
        });
    },
    handlePageChange(page) {
      // 更新当前页数并重新发起查询
      this.currentPage = page;
      this.handleSearch(false, false);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.handleSearch(false, false);
    },
    handleSortChange({ prop, order }) {
      // 更新排序字段和顺序并重新发起查询
      this.sortField = prop;
      this.sortOrder = order === 'ascending' ? 'asc' : 'desc';
      this.handleSearch(false, true);
    },
    fetchActions() {
      // 获取操作行为列表
      axiosInstance.get(api.getActionList)
        .then(response => {
          if (response.data.status === 'success') {
            this.actions = response.data.data.action_list;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error('获取操作行为列表失败：' + error);
        });
    }
  },
  mounted() {
    this.handleSearch(false, false);
    this.fetchActions();
  }
};
</script>

<style scoped>
.el-form-item {
  margin-right: 20px;
}

.card-title {
  font-size: 1rem;
  /* Adjust this to your preferred size */
  margin-bottom: 0.1rem;
}

.f-width {
  width: 220px;
}

.f-width-a {
  width: 100% !important;
}

.h-width {
  width: 105px !important;
}

.pagination-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  /* 保持内容不换行 */
  -ms-overflow-style: none;
  /* 针对 IE 和 Edge 隐藏滚动条 */
  scrollbar-width: none;
  /* 针对 Firefox 隐藏滚动条 */
}

.pagination-wrapper::-webkit-scrollbar {
  display: none;
  /* 针对 Chrome、Safari 隐藏滚动条 */
}

.el-pagination {
  display: inline-block;
  /* 使分页组件在一行内 */
}
</style>