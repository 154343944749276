<template>
  <div v-if="hasPermission">
    <el-card class="card pt-1">
      <el-row :gutter="20" class="mb-2">
        <el-col :span="12">
          <h3 class="card-title">辅导员信息</h3>
        </el-col>
        <el-col :span="12" style="text-align: right;">
          <el-button type="primary" @click="openCreateAdminDialog">添加管理员</el-button>
        </el-col>
      </el-row>

      <el-table :data="admins" v-loading="loading">
        <el-table-column prop="phone_number" label="手机号" min-width="150"></el-table-column>
        <el-table-column prop="name" label="姓名" min-width="150"></el-table-column>
        <el-table-column prop="email" label="邮箱" min-width="150"></el-table-column>
        <el-table-column prop="role" label="角色" min-width="70">
          <template slot-scope="scope">
            <span>{{ scope.row.role === 1 ? '超级管理员' : '管理员' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="grade_id_list" label="可管理年级" min-width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.role === 1">所有</span>
            <span v-else>{{ scope.row.grade_id_list.join(', ') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="class_id_list" label="可管理班级" min-width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.role === 1">所有</span>
            <span v-else>{{ scope.row.class_id_list.join(', ') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <el-button type="primary" @click="openEditDialog(scope.row)"
                       v-if="scope.row.role !== 1 ||(scope.row.role == 1 && scope.row.admin_id == userId)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog title="添加管理员" :visible.sync="createAdminDialogVisible" width="75%">
      <el-form :model="createAdminForm">
        <el-form-item label="姓名">
          <el-input v-model="createAdminForm.name" class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="createAdminForm.phone" class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="createAdminForm.email" class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input type="password" v-model="createAdminForm.password" class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="createAdminForm.role" class="f-width-a">
            <el-option label="管理员" :value="0"></el-option>
            <el-option label="超级管理员" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可管理年级">
          <el-select v-model="createAdminForm.grade_id_list" multiple class="f-width-a" @change="handleGradeChange">
            <el-option v-for="item in grades" :key="item" :label="item"
                       :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可管理班级">
          <el-select v-model="createAdminForm.class_id_list" multiple class="f-width-a">
            <el-option v-for="item in filteredClasses" :key="item.class_id" :label="item.class_id"
                       :value="item.class_id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createAdminDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitCreateAdmin">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑管理员" :visible.sync="editDialogVisible" width="70%">
      <el-form :model="editForm">
        <el-form-item label="手机号">
          <el-input v-model="editForm.phone_number" class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="editForm.email" class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input type="password" v-model="editForm.password" class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="可管理年级">
          <el-select v-model="editForm.grade_id_list" multiple class="f-width-a" @change="handleGradeChange">
            <el-option v-for="item in grades" :key="item" :label="item"
                       :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可管理班级">
          <el-select v-model="editForm.class_id_list" multiple class="f-width-a">
            <el-option v-for="item in filteredClasses" :key="item.class_id" :label="item.class_id"
                       :value="item.class_id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitEdit">保存</el-button>
      </span>
    </el-dialog>

  </div>
  <div v-else>
    <p>你没有权限访问此页面。</p>
  </div>
</template>

<script>
import api from "../../api"
import {fetchClasses, fetchGrades, fetchClassIdsByGrades} from "../../api/api_util";
import axiosInstance from "../../api/axiosInstance";  // 确保路径正确

export default {
  data() {
    return {
      userId: localStorage.userId,
      uploadScores: [],
      hasPermission: false,
      admins: [],
      loading: false,
      createAdminDialogVisible: false,
      editDialogVisible: false,
      createAdminForm: {
        name: '',
        phone: '',
        email: '',
        password: '',
        role: 0,
        grade_id_list: [],
        class_id_list: []
      },
      editForm: {
        id: '',
        email: '',
        password: '',
        grade_id_list: [],
        class_id_list: []
      },
      grades: [],
      classes: [],
      filteredClasses: []
    };
  },
  methods: {
    openEditDialog(admin) {
      this.editForm = {
        id: admin.admin_id,
        phone_number: admin.phone_number,
        email: admin.email,
        password: '',
        grade_id_list: admin.grade_id_list,
        class_id_list: admin.class_id_list
      };
      this.editDialogVisible = true;
      this.handleGradeChange();
    },
    async submitEdit() {
      if (!this.editForm.phone_number) {
        this.$message.error('请填写完整的管理员信息');
        return;
      }
      if (!/^\d{11}$/.test(this.editForm.phone_number)) {
        this.$message.error('请输入正确的11位手机号');
        return;
      }
      // 邮箱格式校验
      if (this.editForm.email.length > 0 && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.editForm.email)) {
        this.$message.error('请输入有效的邮箱地址');
        return;
      }
      try {
        const response = await axiosInstance.post(api.editAdmin, this.editForm);
        const resData = response.data;
        if (resData.status === 'success') {
          this.$message.success('管理员信息更新成功');
          this.editDialogVisible = false;
          await this.fetchAdmins();
        } else {
          this.$message.error(resData.message);
        }
      } catch (error) {
        this.$message.error('管理员信息更新失败，请稍后再试');
      }
    },
    async fetchAdmins() {
      this.loading = true;
      try {
        const response = await axiosInstance.get(api.getAdminList);
        const resData = response.data;
        if (resData.status === 'success') {
          this.admins = resData.data;
        } else {
          this.$message.error(resData.message);
        }
      } catch (error) {
        this.$message.error('获取管理员列表失败，请稍后再试');
      } finally {
        this.loading = false;
      }
    },
    async fetchClassesAndGrades() {
      try {
        this.classes = await fetchClasses();
        this.grades = await fetchGrades();
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    openCreateAdminDialog() {
      this.createAdminDialogVisible = true;
    },
    async handleGradeChange() {
      try {
        if (this.createAdminForm.grade_id_list.length === 0 && this.editForm.grade_id_list.length === 0) {
          return;
        }
        let grade_id_list = []
        if (this.editDialogVisible) {
          grade_id_list = this.editForm.grade_id_list;
        } else if (this.createAdminDialogVisible) {
          grade_id_list = this.createAdminForm.grade_id_list;
        }
        const classData = await fetchClassIdsByGrades(grade_id_list);
        this.filteredClasses = [];
        for (const grade_id of grade_id_list) {
          if (classData[grade_id]) {
            this.filteredClasses = this.filteredClasses.concat(classData[grade_id].map(class_id => ({
              class_id,
              grade_id
            })));
          }
        }
      } catch (error) {
        this.$message.error('获取班级列表失败，请稍后再试');
      }
    },
    async submitCreateAdmin() {
      // 校验输入
      if (!this.createAdminForm.name || !this.createAdminForm.phone || !this.createAdminForm.password) {
        this.$message.error('请填写完整的管理员信息');
        return;
      }
      if (!/^\d{11}$/.test(this.createAdminForm.phone)) {
        this.$message.error('请输入正确的11位手机号');
        return;
      }


      // 邮箱格式校验
      if (this.createAdminForm.email.length > 0 && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.createAdminForm.email)) {
        this.$message.error('请输入有效的邮箱地址');
        return;
      }

      if (this.createAdminForm.role === 0) {
        if (this.createAdminForm.grade_id_list.length === 0 && this.createAdminForm.class_id_list.length === 0) {
          this.$message.error("普通管理员必须限定可管理年级或者班级")
          return;
        }
      } else {
        // 超级管理员直接清空
        this.createAdminForm.grade_id_list = [];
        this.createAdminForm.class_id_list = [];
      }


      try {
        const response = await axiosInstance.post(api.createAdmin, this.createAdminForm);
        const resData = response.data;
        if (resData.status === 'success') {
          this.$message.success('管理员创建成功');
          this.createAdminDialogVisible = false;
          await this.fetchAdmins();
        } else {
          this.$message.error(resData.message);
        }
      } catch (error) {

        this.$message.error('管理员创建失败，请稍后再试');
      }
    }
  },
  async mounted() {
    const roleType = localStorage.getItem('role_type');
    if (roleType === '1') {
      this.hasPermission = true;
      await this.fetchClassesAndGrades();
      await this.fetchAdmins();  // 加载所有管理员
    } else {
      this.$message.error('你没有权限访问此页面');
      await this.$router.push({path: "/login"});
    }
  }
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 12px;
}

.el-input,
.el-select {
  width: 100%;
}

.card-title {
  font-size: 1rem; /* Adjust this to your preferred size */
  margin-bottom: 0.1rem;
}

.f-width {
  width: 100%;
}
</style>