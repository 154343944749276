import axios from 'axios';
import router from '../router';

const axiosInstance = axios.create({
    timeout: 10000,  // 设置请求超时时间
});

axiosInstance.interceptors.request.use(config => {
    const token = localStorage.token;
    if (token) {
        config.headers.Authorization = `${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        // console.log("enter failed process");
        // Token 过期或无效
        localStorage.removeItem('token');
        localStorage.setItem('tokenExpired', 'true');  // 设置登录状态过期标志
        router.push('/login');
    }
    return Promise.reject(error);
});

export default axiosInstance;