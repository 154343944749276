<template>
  <a-card title="通知" >
    <p>{{ content }}</p>
    <div slot="extra" style="font-size: 12px; text-align: right;">{{ time }}</div>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      content: "欢迎使用北航飞行学院准军事化管理平台",
      time: this.getCurrentTime()
    };
  },
  methods: {
    getCurrentTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}年${month}月${day}日`;
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token == null || token.length === 0) {
      this.$message.error('请先登录');
      this.$router.push({path: "/login"});
      return;
    }
    if (localStorage.role_type == null) {
      this.$message.error('请先登录');
      this.$router.push({path: "/login"});
      return;
    }
  }
};
</script>

<style>
/* 样式 */
</style>
