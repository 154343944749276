<template>
  <div class="row mb-3">
    <el-card class="card">

      <el-upload
          ref="upload"
          :http-request="handleImport"
          :show-file-list="false"
          accept=".xlsx,.xls"
          action="#"
          class="upload-demo"
          drag
          multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">只能上传xlsx、xls文件，且不超过500kb</div>
      </el-upload>

      <el-table :data="paginatedStudents" style="width: 100%; margin-top: 20px;">
        <el-table-column prop="student_number" label="学号"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="class_id" label="班级"></el-table-column>
        <el-table-column prop="grade_id" label="年级"></el-table-column>
        <el-table-column prop="phone" label="联系方式"></el-table-column>
        <el-table-column prop="province" label="省份"></el-table-column>
        <el-table-column prop="airline_name" label="航空公司"></el-table-column>

      </el-table>
      <div class="pagination-wrapper">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="students.length"
            :page-size="pageSize"
            :current-page.sync="currentPage"
            @current-change="handlePageChange"
            style="margin-top: 20px;"
        ></el-pagination>
      </div>

      <el-button type="success" @click="saveStudents" :disabled="students.length === 0">确认并保存</el-button>
      <el-button type="info" @click="exportStudentModel">导出上传模版</el-button>
    </el-card>
  </div>
</template>

<script>
import api from "../../api";
import * as xlsx from "xlsx";
import axiosInstance from "../../api/axiosInstance";
import XlsxPopulate from 'xlsx-populate';
import { provinces, airlines } from '../../util/constants.js';

export default {
  data() {
    return {
      provinces,
      airlines,
      selectedFile: null,
      students: [],
      currentPage: 1,
      pageSize: 10
    };
  },
  computed: {
    paginatedStudents() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.students.slice(start, end);
    }
  },
  methods: {
    exportStudentModel() {
      // 省份列表
      const provinces = this.provinces;
      console.log(provinces, 'provinces')

      XlsxPopulate.fromBlankAsync()
        .then(workbook => {
          const sheet = workbook.sheet(0);
          sheet.name("Model");

          // 添加表头
          sheet.cell("A1").value("学号");
          sheet.cell("B1").value("姓名");
          sheet.cell("C1").value("班级");
          sheet.cell("D1").value("年级");
          sheet.cell("E1").value("联系方式");
          sheet.cell("F1").value("省份");
          sheet.cell("G1").value("航空公司");

          // 添加数据验证（下拉列表）到省份和航空公司列
          sheet.range("F2:F1000").dataValidation({
            type: "list",
            allowBlank: true,
            formula1: `"${provinces.join(",")}"`,
            showDropDown: true
          });


          // 填充一个示例行
          sheet.cell("A2").value("输入学号");
          sheet.cell("B2").value("输入姓名");
          sheet.cell("C2").value("输入班级");
          sheet.cell("D2").value("输入年级(2021，2022，2023等样式");
          sheet.cell("E2").value("输入联系方式(11位手机号)");
          sheet.cell("F2").value("选择省份");
          sheet.cell("G2").value("选择航空公司");

          // 生成二进制内容并保存文件
          return workbook.outputAsync();
        })
        .then(blob => {
          saveAs(blob, "studentModel.xlsx");
        })
        .catch(err => {
          console.error(err);
        });
    },
    handleImport(event) {
      this.students = [];
      const file = event.file
      if (file) {
        this.readExcel(file)
      }
    },
    readExcel(files) {
      const fileReader = new FileReader()
      fileReader.onload = (files) => {
        try {
          const data = files.target.result
          const workbook = xlsx.read(data, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0] // 取第一张表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]) // 生成json表格内容
          // console.log(ws, 'ws是表格里的数据，且是json格式')
          let index = 0;
          for (let i of ws) {
            index++;
            const { 学号, 姓名, 班级, 年级, 联系方式, 省份, 航空公司 } = i
            let student = {
              student_number: 学号,
              name: 姓名,
              class_id: 班级,
              grade_id: 年级,
              phone: 联系方式,
              province: 省份,
              airline_name: 航空公司
            }
            let errors = []
            if (!student.student_number || !student.name || !student.class_id || !student.grade_id || !student.phone || !student.province || !student.airline_name) {
              errors.push(`第 ${index} 行信息不足`);
            }
            if (!/^\d{11}$/.test(String(student.phone))) {
              errors.push(`第 ${index} 行手机号格式不正确，需为11位数字`);
            }
            if (/\d/.test(String(student.name))) {
              errors.push(`第 ${index} 行姓名不能包含数字`);
            }
            /*
            if (!/^\d+$/.test(String(student.class_id))) {
              errors.push(`第 ${index} 行班级必须是数字`);
            }
            */
            /*
             if (!/^\d+$/.test(String(student.grade_id))) {
               errors.push(`第 ${index} 行年级必须是数字`);
             }
             if (String(student.grade_id).length !== 4) {
               errors.push(`第 ${index} 行年级必须是xxxx样式，如2024`);
             }
             */
            if (errors.length > 0) {
              this.$message.error(errors.join(',\n'));
              this.students = [];
              return;
            } else {
              this.students.push(student)
            }
          }
        } catch (e) {
          console.log(e)
          this.$message.warning('请重试')
          return false
        }
      }
      fileReader.readAsBinaryString(files)
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    saveStudents() {
      axiosInstance.post(api.saveStudentData, {
        students: this.students
      }, {
        headers: { Authorization: localStorage.token }
      })
        .then(response => {
          let status = response.data.status
          if (status === 'success') {
            this.$message.success("保存成功")
            //清空数据
            this.students = []
          } else if (status === 'error') {
            this.$message.warning("保存失败")
          }
        })
        .catch(error => {
          this.$notification.error({
            message: "错误",
            description:
              ((error.response || {}).data || {}).message ||
              "请求出现错误，请稍后再试",
            duration: 4
          })
        });
    }
  },
  mounted() {
    const roleType = localStorage.getItem('role_type');
    if (roleType === '1') {
      console.log("Check Pass")
    } else {
      this.$message.error('你没有权限访问此页面');
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style scoped>
.upload-demo {
  margin-bottom: 20px;
}

.pagination-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  /* 保持内容不换行 */
  -ms-overflow-style: none;
  /* 针对 IE 和 Edge 隐藏滚动条 */
  scrollbar-width: none;
  /* 针对 Firefox 隐藏滚动条 */
}

.pagination-wrapper::-webkit-scrollbar {
  display: none;
  /* 针对 Chrome、Safari 隐藏滚动条 */
}

.el-pagination {
  display: inline-block;
  /* 使分页组件在一行内 */
}
</style>