<template>
  <div class="mb-2">
    <el-card class="card">
      <div class="col-md-12">
        <div slot="header" class="clearfix">
          <h3 class="card-title">学期管理</h3>
          <el-button type="primary" @click="showAddDialog" style="float: right;">添加学期</el-button>
        </div>

        <el-table :data="semesters" style="width: 100%; margin-top: 20px">
          <el-table-column prop="semester_name" label="学期名称"></el-table-column>
          <el-table-column prop="start_date" label="开始日期"></el-table-column>
          <el-table-column prop="end_date" label="结束日期"></el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="showEditDialog(scope.row)">编辑</el-button>
              <el-button size="small" type="danger" @click="deleteSemester(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 添加/编辑学期对话框 -->
    <el-dialog :visible.sync="dialogVisible" title="学期信息" width="80%">
      <el-form :model="currentSemester" >
        <el-form-item label="学期名称">
          <el-input v-model="currentSemester.semester_name" class="f-width-a"  placeholder="输入学期名称"></el-input>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker v-model="currentSemester.start_date" value-format="yyyy-MM-dd" type="date"
                          class="f-width-a"  placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker v-model="currentSemester.end_date" value-format="yyyy-MM-dd" type="date"
                          class="f-width-a"  placeholder="选择日期"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveSemester">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api/index";

export default {
  data() {
    return {
      semesters: [],
      dialogVisible: false,
      currentSemester: {
        id: null,
        semester_name: '',
        start_date: '',
        end_date: ''
      },
      isEditMode: false
    };
  },
  mounted() {
    this.fetchSemesters();
  },
  methods: {
    fetchSemesters() {
      axiosInstance.get(api.listSemesters)
          .then(response => {
            this.semesters = response.data.data;
          })
          .catch(error => {
            this.$message.error('获取学期信息失败: ' + error);
          });
    },
    showAddDialog() {
      this.isEditMode = false;
      this.currentSemester = {
        id: null,
        semester_name: '',
        start_date: '',
        end_date: ''
      };
      this.dialogVisible = true;
    },
    showEditDialog(semester) {
      this.isEditMode = true;
      this.currentSemester = {...semester};
      this.dialogVisible = true;
    },
    saveSemester() {
      if (this.isEditMode) {
        // 更新学期
        axiosInstance.put(api.updateSemester(this.currentSemester.id), this.currentSemester).then(() => {
          this.fetchSemesters();
          this.dialogVisible = false;
          this.$message.success('学期更新成功');
        })
            .catch(error => {
              this.$message.error('更新学期失败: ' + error);
            });
      } else {
        // 创建新学期
        axiosInstance.post(api.createSemester, this.currentSemester)
            .then(() => {
              this.fetchSemesters();
              this.dialogVisible = false;
              this.$message.success('学期创建成功');
            })
            .catch(error => {
              this.$message.error('创建学期失败: ' + error);
            });
      }
    },
    deleteSemester(id) {
      this.$confirm('确定删除此学期吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.delete(api.deleteSemester(id))
            .then(() => {
              this.fetchSemesters();
              this.$message.success('学期删除成功');
            })
            .catch(error => {
              this.$message.error('删除学期失败: ' + error);
            });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    }
  }
};
</script>

<style scoped>


.el-table .el-button {
  margin-right: 10px;
}

.dialog-footer {
  text-align: right;
}

.rounded-card {
  border-radius: 15px; /* 圆角设置 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

.rounded-card .el-card__header {
  border-top-left-radius: 15px; /* 头部左上圆角 */
  border-top-right-radius: 15px; /* 头部右上圆角 */
}

.card-title {
  font-size: 1rem; /* Adjust this to your preferred size */
  margin-bottom: 0.1rem;
}

.rounded-card .el-card__body {
  border-bottom-left-radius: 15px; /* 身体左下圆角 */
  border-bottom-right-radius: 15px; /* 身体右下圆角 */
}
.f-width {
  width: 220px;
}

.f-width-a {
  width: 100%!important;
}

.h-width {
  width: 105px!important;
}

</style>