<template>
  <div class="bg-image">
    <div class="main container">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-8 col-sm-10">
          <div class="card">
            <div class="card-body">
              <div class="title text-center mb-4">飞行学院准军事化管理系统</div>
              <a-form id="formLogin" class="user-layout-login" ref="formLogin">
                <a-alert
                    v-if="isLoginError"
                    type="error"
                    show-icon
                    class="mb-4"
                    message="用户名或密码错误"
                />
                <a-form-item>
                  <a-input
                      size="large"
                      type="text"
                      placeholder="手机号"
                      v-model="phone"
                      v-decorator="[
                        'username',
                        {
                          rules: [
                            { required: true, message: '请输入手机号' },
                            { validator: handleUsername }
                          ],
                          validateTrigger: 'change'
                        }
                      ]"
                  >
                    <a-icon
                        slot="prefix"
                        type="user"
                        :style="{ color: 'rgba(0,0,0,.25)' }"
                    />
                  </a-input>
                </a-form-item>

                <a-form-item>
                  <a-input
                      size="large"
                      type="password"
                      autocomplete="false"
                      placeholder="密码"
                      v-model="password"
                      v-decorator="[
                        'password',
                        {
                          rules: [{ required: true, message: '请输入密码' }],
                          validateTrigger: 'blur'
                        }
                      ]"
                  >
                    <a-icon
                        slot="prefix"
                        type="lock"
                        :style="{ color: 'rgba(0,0,0,.25)' }"
                    />
                  </a-input>
                </a-form-item>

                <a-form-item>
                  <div class="row">
                    <div class="col-8">
                      <a-input
                          size="large"
                          placeholder="验证码"
                          v-model="captcha"
                          v-decorator="[
                            'captcha',
                            {
                              rules: [{ required: true, message: '请输入验证码' }],
                              validateTrigger: 'blur'
                            }
                          ]"
                      >
                        <a-icon
                            slot="prefix"
                            type="safety-certificate"
                            :style="{ color: 'rgba(0,0,0,.25)' }"
                        />
                      </a-input>
                    </div>
                    <div class="col-4 d-flex align-items-center">
                      <img :src="captchaImage" alt="验证码" @click="refreshCaptcha" class="img-fluid cursor-pointer"
                           style="height: 40px;"/>
                    </div>
                  </div>
                </a-form-item>

                <a-form-item class="mt-3">
                  <a-button
                      size="large"
                      type="primary"
                      class="login-button w-100"
                      :loading="state.loginBtn"
                      :disabled="state.loginBtn"
                      @click="doLogin"
                  >登录
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/index"
import axiosInstance from "../../api/axiosInstance";

export default {
  data() {
    return {
      phone: "",
      password: "",
      captcha: "",
      captchaImage: '',
      customActiveKey: "tab1",
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      tokenExpired: false
    };
  },
  created() {
  },
  mounted() {
    if (localStorage.getItem('tokenExpired') === 'true') {
      this.tokenExpired = true;
      this.$message.warning("登录状态失效，请重新登录！")
      localStorage.removeItem('tokenExpired');  // 移除标志
    }
    this.refreshCaptcha();
  },
  methods: {
    // handler
    handleUsername(rule, value, callback) {
      if (!value) {
        // 手机号为空，直接返回错误
        return callback(new Error('请输入手机号'));
      }
      if (value.length != 11) {
        // 手机号太短了
        return callback(new Error('请输入手机号'));
      }
      // 手机号长度符合要求，不报错
      callback();
    },
    handleTabClick(key) {
      this.customActiveKey = key;
    },
    refreshCaptcha() {
      // 请求后端获取验证码图片
      fetch(api.Img)
          .then(response => response.blob())
          .then(imageBlob => {
            // 将图片 Blob 对象转换为 Data URL
            const reader = new FileReader();
            reader.onload = () => {
              // 将 Data URL 赋值给 captchaImage，触发页面更新
              this.captchaImage = reader.result;
            };
            reader.readAsDataURL(imageBlob);
          })
          .catch(error => {
            console.error('Error fetching captcha:', error);
          });
    },
    doLogin() {
      if (this.password.length == 0 || this.captcha.length == 0 || this.phone.length == 0) {
        this.$message.warning("请输入所有信息后登录")
        return
      }
      axiosInstance.post(api.Login, {
        phone: this.phone,
        password: this.password,
        captcha: this.captcha,
      })
          .then(response => {
            console.log(response)
            let status = response.data.status
            if (status === 'success') {
              localStorage.token = response.data.data.token
              localStorage.userId = response.data.data.userid
              console.log("token:", localStorage.token)
              console.log("userId:", localStorage.userId)
              localStorage.role_type = response.data.data.role
              this.$router.push({path: "/board"});
              //延迟 1 秒显示欢迎信息
              let show_info = `欢迎回来, ` + response.data.data.username;
              setTimeout(() => {
                this.$notification.success({
                  message: "欢迎",
                  description: show_info
                });
              }, 1000);
              this.isLoginError = false;
            } else if (status === 'error') {
              this.$message.warning(response.data.message)
              this.refreshCaptcha()
            }
          })
          .catch(error => {
            this.isLoginError = true;
            this.$notification["error"]({
              message: "错误",
              description:
                  ((error.response || {}).data || {}).message ||
                  "请求出现错误，请稍后再试",
              duration: 4
            })
          });
    }
  }
}
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}

.main {
  padding: 20px; /* 调整这个值来增加或减少向下移动的距离 */
  margin-top: 200px;
}

.title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  margin-bottom: 10px;
}

.label {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-bottom: 16px;
}

.bg-image {
  background-image: url('../../assets/back.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
