import axiosInstance from './axiosInstance';  // 确保路径正确
import api from "./index";

export async function fetchClasses() {
    try {
        const response = await axiosInstance.get(api.getClassInfo);
        const resData = response.data;
        if (resData.status === 'success') {
            return resData.data;
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取班级信息失败，请稍后再试');
    }
}

function formatCategories(categories) {
    let formattedCategories = [];
    for (let category of categories) {
        let formattedCategory = {
            value: category.category_id,
            label: category.category_name,
            children: []
        };
        for (let sub of category.sub_categories) {
            formattedCategory.children.push({
                value: sub.sub_category_id,
                label: sub.sub_category_name,
            });
        }
        formattedCategories.push(formattedCategory);
    }
    return formattedCategories;
}

export async function fetchCategories() {
    try {
        const response = await axiosInstance.get(api.getCategories);
        const resData = response.data;
        if (resData.status === 'success') {
            let categories = resData.data;
            return formatCategories(categories);
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取分类信息失败，请稍后再试');
    }
}

export async function fetchGrades() {
    try {
        const response = await axiosInstance.get(api.getGradeInfo);
        const resData = response.data;
        if (resData.status === 'success') {
            return resData.data;
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取年级信息失败，请稍后再试');
    }
}

export async function fetchClassIdsByGrades(gradeIds) {
    try {
        const response = await axiosInstance.post(api.getClassIdsByGrades, {grade_ids: gradeIds});
        const resData = response.data;
        if (resData.status === 'success') {
            return resData.data;
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取班级列表失败，请稍后再试');
    }
}
