import { render, staticRenderFns } from "./StudentUpload.vue?vue&type=template&id=fc6132ce&scoped=true"
import script from "./StudentUpload.vue?vue&type=script&lang=js"
export * from "./StudentUpload.vue?vue&type=script&lang=js"
import style0 from "./StudentUpload.vue?vue&type=style&index=0&id=fc6132ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc6132ce",
  null
  
)

export default component.exports