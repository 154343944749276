<template>
  <div>
    <el-table :data="tableData" border>
      <el-table-column prop="label" label="信息"></el-table-column>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <el-input v-model="userData[scope.$index]"
                    :disabled="!isEditing||scope.$index === 0 || scope.$index===2"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" style="margin-top: 20px;" @click="toggleEdit">{{
        isEditing ? '取消' : '编辑'
      }}
    </el-button>
    <el-button type="primary" style="margin-top: 20px;" @click="submit" :disabled="!isEditing">提交</el-button>
  </div>
</template>

<script>
import axios from "axios";
import api from "../../api";

export default {
  data() {
    return {
      tableData: [
        {label: '手机号', value: '1234567890'},
        {label: '昵称', value: 'User1'},
        {label: '注册时间', value: 'Company A'},
        // 添加更多的用户信息...
      ],
      userData: [],
      isEditing: false,
    };
  },
  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
      // 编辑状态切换时，初始化 userData 数组
      if (this.isEditing) {
        this.userData = this.tableData.map(item => item.value);
      } else {
        this.userData = this.tableData.map(item => item.value);
      }
    },
    submit() {
      console.log('Modified data:', this.userData);
      // 在这里将修改后的数据提交给后端保存
      axios.post(api.EditUserInfo, {
        user_id: localStorage.getItem('userId'),
        phone_number: this.userData[0],
        full_name: this.userData[1],
      }, {
        headers: {Authorization: localStorage.token}
      })
          .then(response => {
            if (response.data.code === 1) {
              this.$message.success("修改成功")
              setTimeout(() => {
                window.location.reload();
              }, 100); // Redirect to another page after 1 second
            }
          })
          .finally(() => {
            this.showSpin = false; // Hide the spinning circle
          });
    },
  },
  mounted() {
    axios.post(api.UserInfo, {
      uid: localStorage.getItem('userId')
    }, {
      headers: {Authorization: localStorage.token}
    })
        .then(response => {
          if (response.data.code === 1) {
            console.log(response.data)
            console.log("Start Assign Value")
            this.tableData = [
              {label: '手机号', value: response.data.users[0].phone_number},
              {label: '昵称', value: response.data.users[0].full_name},
              {label: '注册时间', value: response.data.users[0].registration_time},
            ];
            this.userData = this.tableData.map(item => item.value);
            console.log(this.tableData)
          }
        })
        .finally(() => {
          this.showSpin = false; // Hide the spinning circle
        });
  }
};
</script>

<style>
/* 可选的样式 */
</style>
