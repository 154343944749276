<template>
  <div class="container p-1">
    <!-- 查询选项 -->
    <div class="row">
      <div class="col-md-12">
        <el-card class="card mb-2">
          <h3 class="card-title">申请记录查询</h3>
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="申请类型">
              <template v-if="isWideScreen">
                <el-cascader
                    ref="search_cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleTypeChange"
                    clearable></el-cascader>
              </template>
              <template v-else>
                <el-cascader-panel
                    ref="search_cascader"
                    class="f-width"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    placeholder="选择类型"
                    @change="handleTypeChange"
                    clearable></el-cascader-panel>
                <el-button @click="handleReset" class="f-width">清除</el-button>
              </template>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker v-model="searchForm.startTime" type="date" class="h-width" value-format="yyyy-MM-dd"
                              placeholder="开始日期"></el-date-picker>
              -
              <el-date-picker v-model="searchForm.endTime" type="date" class="h-width" value-format="yyyy-MM-dd"
                              placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="申请状态">
              <el-select v-model="searchForm.status" placeholder="选择申请状态" class="f-width">
                <el-option label="申请中" value="0"></el-option>
                <el-option label="通过" value="1"></el-option>
                <el-option label="拒绝" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <!-- 查询结果 -->
    <div class="row  mt-2">
      <div class="col-md-12">
        <el-card>
          <el-table :data="tableData" style="width: 100%" @sort-change="handleSortChange">
            <el-table-column prop="student_number" label="学号" witdh="60px" sortable="custom"></el-table-column>
            <el-table-column prop="student_name" label="姓名" witdh="50px"></el-table-column>
            <el-table-column prop="apply_category" label="申请类别" witdh="40px" sortable="custom"></el-table-column>
            <el-table-column prop="apply_subcategory" label="申请子类别" witdh="40px"
                             sortable="custom"></el-table-column>
            <el-table-column prop="score" label="申请加分数" witdh="50px"
                             sortable="custom"></el-table-column>
            <el-table-column prop="apply_remarks" label="申请备注" width="100px"></el-table-column>
            <el-table-column prop="create_time" label="申请时间" sortable="custom"></el-table-column>
            <el-table-column prop="update_time" label="审批时间" sortable="custom"
                             :formatter="formatUpdateTime"></el-table-column>
            <el-table-column prop="status" label="状态" width="100" :formatter="formatStatus">
              <template slot-scope="scope">
                <el-tag :type="getStatusType(scope.row.status)">
                  {{ formatStatus(scope.row.status) }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="apply_file_path" label="证明材料" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="viewFile(scope.row.apply_file_path)">查看</el-button>
              </template>
            </el-table-column>

            <el-table-column prop="actions" label="审核" width="120">
              <template slot-scope="scope">
                <el-button-group>
                  <el-button size="small" v-if="scope.row.status == 0" type="primary"
                             @click="confirmAction(scope.row, true)">
                    通过
                  </el-button>
                  <el-button size="small" v-if="scope.row.status == 0" type="danger"
                             @click="confirmAction(scope.row, false)">
                    拒绝
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                @current-change="handlePageChange"
            />
          </div>
        </el-card>
        <!-- 确认对话框 -->
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible">
          <span>{{ dialogMessage }}</span>
          <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="modifyRecordStatus(currentRecord.id, currentAction)">确认</el-button>
      </span>
        </el-dialog>
        <el-dialog
            v-if="isImageVisible"
            :visible.sync="isImageVisible"
            width="95%"
            @close="handleImgClose">
          <el-image :src="imageSrc" fit="contain" class="f-width-a"></el-image>
        </el-dialog>
      </div>
    </div>
  </div>
</template>


<script>
import axiosInstance from "../../api/axiosInstance";  // 假设你有这个实例配置
import api from "../../api/index";
import { fetchCategories } from "../../api/api_util";

export default {
  data() {
    return {
      isWideScreen: window.innerWidth >= 768, // 可以根据需要调整宽度阈值
      searchForm: {
        applyCategory: '',
        applySubcategory: '',
        startTime: '',
        endTime: '',
        status: '',
        applyRemarks: ''
      },
      categories: [],  // 获取类别数据
      subcategories: [],  // 获取子类别数据
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      categories_cascader: [],
      dialogTitle: '',
      dialogMessage: '',
      currentRecord: {},
      currentAction: true,
      dialogVisible: false,
      isImageVisible: false,
      imageSrc: ''
    };

  },
  methods: {
    handleReset() {
      this.$refs.search_cascader.checkedValue = "";
    },
    handleImgClose() {
      this.isImageVisible = false;  // 关闭图片查看器时，将 isImageVisible 重置为 false
    },
    viewFile(filePath) {
      let newFilePath = '/uploads/' + filePath;

      // 检查文件后缀名是否为图片格式
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      const fileExtension = newFilePath.split('.').pop().toLowerCase();

      if (imageExtensions.includes(fileExtension)) {
        this.imageSrc = newFilePath;
        this.isImageVisible = true;
      } else if (fileExtension === 'pdf') {
        // 如果文件是 PDF，跳转到 PdfViewer 页面
        let pdfPath = "pdf/web/viewer.html?file=" + encodeURIComponent(newFilePath);
        window.open(pdfPath, '_blank');
      }
    },
    handleTypeChange(val) {
      console.log(val);
      const checkedNodes = this.$refs['search_cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        this.searchForm.applyCategory = '';
        this.searchForm.applySubcategory = '';
        return;
      }
      if (checkedNodes[0].pathLabels.length > 0) {
        this.searchForm.applyCategory = checkedNodes[0].pathLabels[0];
        if (checkedNodes[0].pathLabels.length > 1) {
          this.searchForm.applySubcategory = checkedNodes[0].pathLabels[1];
        }
      }
      console.log(this.searchForm);
    },

    handleSearch() {
      const params = {
        apply_category: this.searchForm.applyCategory,
        apply_subcategory: this.searchForm.applySubcategory,
        start_time: this.searchForm.startTime,
        end_time: this.searchForm.endTime,
        status: this.searchForm.status,
        apply_remarks: this.searchForm.applyRemarks,
        page: this.currentPage,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder,
      };

      axiosInstance.get(api.queryApplyRecords, { params })
        .then(response => {
          if (response.data.status === 'success') {
            this.tableData = response.data.data.apply_records;
            this.total = response.data.data.total;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error('获取数据失败：' + error);
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.handleSearch();
    },
    handleSortChange({ prop, order }) {
      this.sortField = prop;
      this.sortOrder = order === 'ascending' ? 'asc' : 'desc';
      this.handleSearch();
    },
    formatUpdateTime(row) {
      if (row.status != 0) {
        return row.update_time
      } else {
        return ''
      }
    },
    formatStatus(status) {
      const statusMap = {
        0: '审核中',
        1: '通过',
        2: '拒绝'
      };
      return statusMap[status];
    },
    getStatusType(status) {
      const typeMap = {
        0: 'info',
        1: 'success',
        2: 'danger'
      };
      return typeMap[status];
    },
    downloadFile(filePath, recordId) {
      // 发送请求到后端下载文件
      axiosInstance.get(api.downloadFile, {
        params: { file_path: filePath, record_id: recordId },
        responseType: 'blob'  // 设置responseType为blob
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filePath.split('/').pop());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(error => {
        this.$message.error('下载文件失败：' + error);
      });
    },
    modifyRecordStatus(recordId, isPass) {
      // 发送通过请求到后端
      let targetStatus = 1
      if (!isPass) {
        targetStatus = 2
      }
      axiosInstance.post(api.updateApplyStatus, {
        apply_id: recordId,
        target_status: targetStatus
      })
        .then(response => {
          if (response.data.status === 'success') {
            this.$message.success('审核完成');
            this.dialogVisible = false;
            this.handleSearch();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error('操作失败：' + error);
        });
    },

    confirmAction(record, action) {
      this.currentRecord = record;
      this.currentAction = action;
      this.dialogTitle = action ? '确认通过' : '确认拒绝';
      this.dialogMessage = `你确认要${action ? '通过' : '拒绝'} ${record.student_number}  ${record.student_name} 同学的加分审批记录吗？`;
      this.dialogVisible = true;
    },
  },
  async mounted() {
    this.categories_cascader = await fetchCategories(true);
    this.categories_cascader.push({
      value: 14,
      label: '其他',
      child: [],
    });
    this.handleSearch();
  }
};
</script>

<style scoped>
.el-form-item {
  margin-right: 20px;
}

.card-title {
  font-size: 1rem;
  /* Adjust this to your preferred size */
  margin-bottom: 0.1rem;
}

.el-table .el-table__header {
  background-color: #f5f7fa;
}

.f-width {
  width: 220px;
}

.f-width-a {
  width: 100% !important;
}

.h-width {
  width: 135px !important;
}

.el-cascader-panel {
  width: 220px;
  overflow-x: auto;
  /* 当内容超出宽度时显示横向滚动条 */
}

.pagination-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  /* 保持内容不换行 */
  -ms-overflow-style: none;
  /* 针对 IE 和 Edge 隐藏滚动条 */
  scrollbar-width: none;
  /* 针对 Firefox 隐藏滚动条 */
}

.pagination-wrapper::-webkit-scrollbar {
  display: none;
  /* 针对 Chrome、Safari 隐藏滚动条 */
}

.el-pagination {
  display: inline-block;
  /* 使分页组件在一行内 */
}
</style>