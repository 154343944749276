<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import {onMounted, onUnmounted} from "vue";

export default {
  name: 'app'
  // setup() {
  //   const windowWidth = ref(window.innerWidth);
  //   const leftSpan = ref(windowWidth.value >= 768 ? 12 : 24);
  //
  //   const updateWindowWidth = () => {
  //     windowWidth.value = window.innerWidth;
  //     leftSpan.value = windowWidth.value >= 768 ? 12 : 24;
  //   };
  //   onMounted(() => {
  //     window.addEventListener('resize', updateWindowWidth);
  //   });
  //   onUnmounted(() => {
  //     window.removeEventListener('resize', updateWindowWidth);
  //   });
  //
  //   return {
  //     leftSpan
  //   }
  // }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
